import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Button, Card, Container, Form, Col, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getToken, userAuthentication } from "../../actions/user";
import { pageInit } from "../../conf/";
import Error from "../Form/Error";
import { platform } from "./../../Style/index";
import LoadPage from "./../Load/LoadPage";
import { Grid } from "@mui/material";
const actions = {
  getToken,
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPage: false,
      email: "",
      password: "",
      recaptchaValue: "",
      valid: {
        email: false,
        password: false,
      },
    };
  }

  onChange =
    (name, check = false) =>
    (event) => {
      if (!check) {
        return this.setState({
          [name]: event.target.value,
        });
      }
      return this.setState({
        [name]: event.target.checked,
      });
    };

  onChangeReCAPTCHA = (value) => {
    this.setState({ recaptchaValue: value });
  };

  sendLogin = () => {
    let valid = {};
    Object.keys(this.state).map(
      (property) =>
        (valid[property] = this.state[property].length === 0 ? true : false)
    );
    this.setState({ valid });
    if (this.state.recaptchaValue.length === 0) {
      return this.setState({
        isAlert: true,
        alert: {
          type: "danger",
          message: "Debes verificar que no eres un robot",
        },
      });
    }
    if (this.formValid()) {
      this.setState({ loadPage: true });
      userAuthentication(this.state)
        .then((response) => {
          if (response.status !== 1) {
            this.setState({
              loadPage: false,
              isAlert: true,
              alert: {
                message: response.codeMessages,
                type: "danger",
              },
            });
            return window.grecaptcha.reset();
          }
          // Se almacena el token en el local storage y a su vez en redux
          localStorage.setItem("KarbonoaTOKEN", response.token);
          this.props.getToken(response.token);
          const EcoRegistryGoBack = localStorage.getItem("EcoRegistryGoBack");
          if (EcoRegistryGoBack) {
            localStorage.removeItem("EcoRegistryGoBack");
            return this.props.history.push(EcoRegistryGoBack);
          }
          const infoUser = jwt_decode(response.token);
          return this.props.history.push(pageInit[infoUser.typeAccount]);
        })
        .catch((err) => console.log(err));
    }
  };

  formValid = () => {
    const properties = Object.keys(this.state);
    for (let i = 0; i < properties.length; i++) {
      if (this.state[properties[i]].length === 0) {
        return false;
      }
    }
    return true;
  };

  render() {
    const { t } = this.props;
    const { loadPage, isAlert, alert, email, password } = this.state;
    return (
      <Container style={{ marginTop: "150px", marginBottom: "167px" }}>
        {document.body.classList.add("new-background")}
        <LoadPage load={loadPage} />
        <Row style={{ marginTop: "30px" }}>
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              marginTop: "20px",
            }}
          >
            <Col
              xs={12}
              sm={12}
              md={12}
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {/* Title */}
            </Col>
          </Grid>
        </Row>
        <Card
          style={{
            margin: "0 auto",
            maxWidth: "500px",
            padding: "30px 46px 30px 46px",
            gap: "10px",
            borderRadius: "38px",
            boxShadow: "5px 4px 8px 0px #00000040",
            opacity: "0px",
          }}
        >
          {/* <Card.Header>
          
          </Card.Header> */}
          <Card.Body>
            <Form>
              <Form.Group>
                <Form.Label>{t("formRegister.email")}</Form.Label>
                <Form.Control
                  isInvalid={this.state.valid.email}
                  type="email"
                  placeholder={t("formRegister.email")}
                  value={email}
                  onChange={this.onChange("email")}
                  autoComplete="autoCompleteOff"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                  isInvalid={this.state.valid.password}
                  type="password"
                  placeholder={t("password")}
                  value={password}
                  onChange={this.onChange("password")}
                  autoComplete="autoCompleteOff"
                />
              </Form.Group>

              <ReCAPTCHA
                style={{ display: "flex", justifyContent: "center", marginTop: "2rem"}}
                sitekey="6LefRPYdAAAAANdQTSZ4NA-OV2BmT3dojawQ8-Gf"
                onChange={this.onChangeReCAPTCHA}
              />
              {isAlert && (
                <Error options={alert.message} value={alert.type}></Error>
              )}
              <Form.Group
                style={{
                  marginTop: "2rem",
                  marginBottom: "2rem",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{
                    ...platform.buttonSuccess,
                    width: "Hug (150px)px",
                    height: "Hug (35px)px",
                    padding: "7px 22px 7px 22px",
                    gap: "24px",
                    borderRadius: "37px",
                    opacity: "0px",
                  }}
                  type="button"
                  onClick={this.sendLogin}
                >
                  {t("buttons.login")}
                </Button>
              </Form.Group>

              <Form.Group className="text-center">
                <Link style={{ color: "#00966C" }} to="password-recovery">
                  {t("forgetPassword")}
                </Link>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const mapStateToProps = ({ user }) => ({
  token: user.token,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
