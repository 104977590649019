import { combineReducers } from "redux";
import { creditConversion } from "./creditConversion";
import { general } from "./general";
import { user } from "./user";

export default combineReducers({
  creditConversion,
  general,
  user,
});
