import { home } from "../api/home";

export const getProjectHome = () => {
  return home.getProject().then((response) => response);
};

export const getNewsletter = (newId) => {
  return home.getNew(newId).then((response) => response);
};
export const getSaveSuscription = (email) => {
  return home.getSave(email).then((response) => response);
};
