import { Countries } from "../api/country";

export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_REGIONS = "SET_REGIONS";
export const SET_CITIES = "SET_CITIES";

const setCountries = (payload) => ({ type: SET_COUNTRIES, payload });
const setRegions = (payload) => ({ type: SET_REGIONS, payload });
const setCities = (payload) => ({ type: SET_CITIES, payload });

export const getCountries = () => {
  return (dispatch) => {
    Countries.getCountries().then((countries) => countries && dispatch(setCountries(countries)));
  };
};

export const getRegionsByCountryId = (countryId) => {
  return (dispatch) => {
    Countries.getRegionsByCountryId(countryId).then((regions) => dispatch(setRegions(regions)));
  };
};

export const getCitiesByRegionId = (countryId, regionId) => {
  return (dispatch) => {
    Countries.getCitiesByRegionId(countryId, regionId).then((cities) => dispatch(setCities(cities)));
  };
};

export const setEmptyRegions = () => {
  return (dispatch) => {
    dispatch(setRegions([]));
  };
};

export const setEmptyCities = () => {
  return (dispatch) => {
    dispatch(setCities([]));
  };
};
