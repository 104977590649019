import React from 'react';
import actionGlobalConsultation from '../actions/gobalConsultation';

// const { t } = useTranslation();

const downloadDocument = async (info) => {
  
    const data = { project_id: info.project_id }
    const result = await actionGlobalConsultation.getDownloadDocument(data);

    if (result.status === 200) {
        window.open(result.response.localFileNameUrl, "_blank");
    }

}

const headers = [
    { id: 'doc', label: 'Documentos' },
    { id: 'action', label: 'Descarga' },
];

const rowsActive = [
    // { id: 0, name: 'Documento de descarga', action: <DownloadForOfflineIcon onClick={() => downloadDocument(receivedState)} /> },
];


const inputsComentsForm = [
    { id: 0, column: 6, label: 'Nombre', name: 'name', type: 'text', variant: 'outlined', fullWidth: true , required: true},
    { id: 2, column: 12, label: 'Correo', name: 'email', type: 'text', variant: 'outlined', fullWidth: true, required: true },
    { id: 3, column: 12, label: 'Empresa', name: 'company', type: 'text', variant: 'outlined', fullWidth: true, required: false }
];

export { downloadDocument, headers, rowsActive, inputsComentsForm };
