import React from "react";
import { Container } from "react-bootstrap";

const Error404 = () => {
  return (
    <Container
      style={{ marginTop: "200px", marginBottom: "131px", color: "#212529" }}
    >
      <h2 style={{ fontSize: "150px", fontWeight: "700" }}>404</h2>
      <p style={{ fontSize: "30px", fontWeight: "700" }}>
        ¡Vaya, no se pudo encontrar esta página!
      </p>
    </Container>
  );
};

export default Error404;
