function formatDateShort(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Format the date as "MM/DD/YYYY"
    const shortDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return shortDate;
}

export default {
    formatDateShort
};