import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Form } from "react-bootstrap";

const Select = ({
  options,
  label,
  onChange,
  value,
  isInvalid = false,
  disabled = false,
  id = "",
  styles = false,
}) => {
  return (
    <Fragment>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        id={id}
        isInvalid={isInvalid}
        disabled={disabled}
        as="select"
        value={value || ""}
        onChange={onChange}
        style={{
          //border:"rgb(85, 156, 152)",
          background: styles ? "#153E35" : "",
          boxShadow: "rgba(0, 0, 0, 1)",
          color: styles ? "white" : "",
          fontWeight: styles ? "bold" : "",
          marginTop: styles ? -30 : "",
          marginBottom: styles ? -15 : "",
          width: styles ? "70%" : "",
          
    
        }}
      >
        {options.map((option, key) => (
          <option
          style={{
            ":checked":{
              backgroundColor: "#59B089"
            }}}
            key={key}
            value={option.value}
            data-json={JSON.stringify(option.data) || "{}"}
          >
            {option.label}
          </option>
        ))}
      </Form.Control>
    </Fragment>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
};

export default Select;
