import { apiGobalConsultation } from "../api/gobalConsultation";

const actionGlobalConsultation = {
    getAll: () => apiGobalConsultation.get().then((response) => response),
    getAllComments: (data) => apiGobalConsultation.getComments(data).then((response) => response),
    getDownloadDocument: (data) => apiGobalConsultation.getDocument(data).then((response) => response),
    getDownloadCommentDocument: (data) => apiGobalConsultation.getCommentDocument(data).then((response) => response),
    postGlobalConsultationCommetn: (data) => apiGobalConsultation.postCommentForm(data).then((response) => response),
    postCommetResponse: (data) => apiGobalConsultation.postRespnse(data).then((response) => response),
}

export default actionGlobalConsultation;