import { project } from "../api/project";

export const getProjectForm = () => {
  return project.getForm().then((response) => response);
};
export const commentsGlobal = (data) => {
  return project.commentsGlobal(data).then((response) => response);
};
export const activeGlobal = (data) => {
  return project.activeGlobal(data).then((response) => response);
};

export const getProjectInitialInformation = (data) => {
  return project.getInitialInformation(data).then((response) => response);
};

export const getFormEmitCertification = (data) => {
  return project.getFormEmitCertification(data).then((response) => response);
};

export const getSerials = (id) => {
  return project.getSerials(id).then((response) => response);
};

export const getSerial = (id, serial) => {
  return project.getSerial(id, serial).then((response) => response);
};

export const getQuantityCertifications = () => {
  return project.getQuantityCertifications().then((response) => response);
};

export const saveProject = (method, data) => {
  return project.save(method, data).then((response) => response);
};

export const getMyProyects = () => {
  return project.getMyProjects().then((response) => response);
};

export const getProjectsPending = () => {
  return project.getPending().then((response) => response);
};

export const deleteProject = (id) => {
  return project.delete(id).then((response) => response);
};

export const activateNextcheckProject = (id) => {
  return project.activateNextcheck(id).then((response) => response);
};

export const getProjectEdit = (id) => {
  return project.getEdit(id).then((response) => response);
};

export const verifySignatureLink = (firm, hashProjectId, hashUserId) => {
  return project
    .verifySignatureLink(firm, hashProjectId, hashUserId)
    .then((response) => response);
};

export const generateVerificationCode = (data) => {
  return project.generateVerificationCode(data).then((response) => response);
};

export const getProjectsPublic = () => {
  return project.getPublics().then((response) => response);
};

export const getProjectPublicFilter = (data) => {
  return project.getPublicFilter(data).then((response) => response);
};

export const getProjectPublic = (projectId) => {
  return project.getPublic(projectId).then((response) => response);
};

export const getProjectDocuments = (projectId, verificationNumber) => {
  return project
    .getDocuments(projectId, verificationNumber)
    .then((response) => response);
};
export const getProjectDocumentZip = (projectId, certificatedId) => {
  return project
    .getDocumentsZip(projectId, certificatedId)
    .then((response) => response);
};

export const projectEmitcertification = (data) => {
  return project.emitCertification(data).then((response) => response);
};

export const projectEmitcertificationkg = (data) => {
  return project.emitCertificationkg(data).then((response) => response);
};

export const projectEmitCertificationTransfer = (data) => {
  return project.emitCertificationTranfer(data).then((response) => response);
};
export const projectEmitCertificationUpdate = (data) => {
  return project.emitCertificationUpdate(data).then((response) => response);
};

export const getProjectEmitCertification = (emitCertificationId) => {
  return project
    .getEmitCertification(emitCertificationId)
    .then((response) => response);
};
export const getAllProjectEmitCertification = () => {
  return project
    .getAllEmitCertification()
    .then((response) => response);
};
export const getWithdrawalInformation = () => {
  return project
    .getWithdrawalInformation()
    .then((response) => response);
};
export const getProjectEmitCertificationKg = (emitCertificationId) => {
  return project
    .getEmitCertificationKg(emitCertificationId)
    .then((response) => response);
};

export const getProjectEmitCertifications = (projectId) => {
  return project.getEmitCertifications(projectId).then((response) => response);
};

export const getProjectCalculatePayment = (projectId, paymentMethod) => {
  return project
    .calculatePayment(projectId, paymentMethod)
    .then((response) => response);
};

export const getProjectCalculatePaymentEmit = (projectId, quantity) => {
  return project
    .calculatePaymentEmit(projectId, quantity)
    .then((response) => response);
};

export const getProjectByStandardId = (standardId) =>
  project.getByStandardId(standardId);

export const getProjectDocument = (documentId) =>
  project.getDocument(documentId);

export const getProjectDocumentGov = (data) => project.getDocumentGov(data);

export const getProjectDocumentLocation = (documentId) =>
  project.getDocumentLocation(documentId);

export const downloadVerificationDocuments = (documentId, verificationNumber) =>
  project.downloadVerificationDocuments(documentId, verificationNumber);

export const publishProject = (id) => project.publish(id);
export const projectStageUpdated = (data) => project.stageUpdated(data);
export const projectSaveNotes = (data) => project.projectSaveNotes(data);

export const signatureConfirmationValidation = (data) => {
  return project
    .signatureConfirmationValidation(data)
    .then((response) => response);
};

export const signatureConfirmationMonitoringReport = (data) => {
  return project
    .signatureConfirmationMonitoringReport(data)
    .then((response) => response);
};

export const signatureConfirmationVerification = (data) => {
  return project
    .signatureConfirmationVerification(data)
    .then((response) => response);
};

export const signatureConfirmationCertification = (data) => {
  return project
    .signatureConfirmationCertification(data)
    .then((response) => response);
};

export const signatureConfirmationCertificationCredit = (data) => {
  return project
    .signatureConfirmationCertificationCredit(data)
    .then((response) => response);
};

export const reportProjects = (data) => {
  return project.reportProjects(data).then((response) => response);
};
