export const platform = {
    buttonSuccess: {
        backgroundColor: "#59B089",
        color: "#fff",
        borderColor: "inherit",
        padding: "7px, 22px, 7px, 22px",
        borderRadius: "37px"
    },
    infoButton: {
        color: "#fff",
        borderColor: "inherit",
        padding: "7px, 22px, 7px, 22px",
        borderRadius: "37px",
        background: "#646687",
        fontSize: "0.864rem",
    }
    
};

