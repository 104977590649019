import { projectEmitCertification } from "../api/projectEmitCertification";
export const getEmitCertificationReport = () => {
  return projectEmitCertification
    .getEmitCertificationReport()
    .then((response) => response);
};

export const getReasonUsingCertificate = (certificatedId) => {
  return projectEmitCertification
    .getReasonUsingCertificate(certificatedId)
    .then((response) => response);
};
