import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Badge from '@mui/material/Badge';
import { Link } from "react-router-dom";

function IconNotifications({ total }) {

  return (
    <Link className="nav-link" to="/notifications">
      <IconButton color="primary" aria-label="add to shopping cart" style={{ color: '#bdbdbd' }}>
        <Badge
          badgeContent={total}
          color="error"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <NotificationsActiveIcon />
        </Badge>
      </IconButton>
    </Link>

  );
}

export default IconNotifications;