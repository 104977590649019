import React, { Component, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import { config as serverUploadConfig } from "../../helpers/serverUpload";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import sectIndicator from "../../images/sect_indicator.svg";
import { bindActionCreators } from "redux";
import { FilePond, registerPlugin } from "react-filepond";
import { getDocumentsRegister } from "../../actions/documentsRegister";
import "react-international-phone/build/index.css";
//import PhoneInput from "react-phone-number-input";
import Helper from "./../Helper/Helper";
import { PhoneInput } from "react-international-phone";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  getCitiesByRegionId,
  getCountries,
  getRegionsByCountryId,
} from "../../actions/country";
import { getTypeAccountsActives } from "../../actions/typeAccount";
import { setUser } from "../../actions/user";
import DocumentNumber from "../Form/DocumentNumber";
import Error from "../Form/Error";
import Select from "../Form/Select";
import { getTermCondition } from "../../actions/termCondition";
import { platform } from "../../Style/index";
import LoadPage from "../Load/LoadPage";
import HabeasData from "./HabeasData";
import Terms from "./Terms";
import { documentsRegister } from "../../api/documentsRegister";
import DialogComponent from "../../components/Project/Dialog";
import "./Register.css";
import iconPrimary from "./../../images/iconPrimary.png";
import iconSegundary from "./../../images/iconSegundary.png";
import fileAndFolder from "./../../images/file-and-folder.png";
import iconTercer from "./../../images/iconTercer.png";
import infoIcon from "./../../images/info-icon.svg";
import accountAdminIcon from "./../../images/account-admin-icon.svg";
import ceoIcon from "./../../images/ceo-icon.png";
import addFileIcon from "./../../images/add-file.png";
import fileUpload from "./../../images/file-upload.png";
import userPaperIcon from "./../../images/user-paper-icon.png";
import paperDocumentIcon from "./../../images/paper-document-icon.png";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview);

const actions = {
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPage: true,
      documentsRegister: {},
      legalPhone: "",
      typesAccounts: [],
      form: {},
      showTerms: false,
      showHabeasData: false,
      isDisabledSave: false,
      typeAccount: "",
      alternativeText: "",
      typeDocument: "1",
      documentNumberType: "",
      name: "",
      documentsRegisterType: [],
      email: "",
      emailAgain: "",
      emailAgainLegal: "",
      address: "",
      modalMessages: false,
      message: "",
      comment: "",
      phone: "",
      contactName: "",
      phoneCode: "",
      country: null,
      region: null,
      city: null,
      commentDialog: "",
      recaptchaValue: "",
      isAuthorized: false,
      termCondition: "",
      habeasData: "",
      isVerificationDigit: false,
      verificationDigit: "",
      formatDocumentNumber: "",
      formatCellPhone: "",
      legalRepresentation: "",
      emailRepresentation: "",
      typeDocumentlegal: "1",
      typeDocumentContact: "1",
      documentNumberlegal: "",
      documentNumberContact: "",
      clearForm: false,
    };
  }

  componentDidMount() {
    this.props.getCountries();
    getTypeAccountsActives().then((response) => {
      if (response.status === 1) {
        const typesAccounts = response.typesAccounts.map((element) => ({
          value: element.id,
          label: element.description,
          data: element,
        }));
        // const defaultAccount = response.typesAccounts.filter((element) => element.id === this.state.typeAccount);
        this.setState({
          typeAccount:
            typesAccounts[0].value.toString() || this.state.typeAccount,
          typesAccounts,
          alternativeText: typesAccounts[0].data.alternativeText || "",
          loadPage: false,
        });
      }
    });
    getDocumentsRegister().then((response) => {
      if (response.status === 1) {
        this.setState({
          documentsRegisterType: response.documentsRegister,
        });
      }
    });
    getTermCondition().then((response) => {
      if (response.status === 1) {
        response.termsConditions.forEach((element) => {
          this.setState({
            [element.name]: element.terms,
          });
        });
      }
    });
  }

  componentWillReceiveProps({ countries, regions, cities }, nextState) {
    const { country, region, city } = this.state;
    const defaultCountry = countries[0];
    if (!country && countries.length) {
      this.props.getRegionsByCountryId(defaultCountry.id);
      this.setState({
        country: defaultCountry.id,
        formatDocumentNumber: defaultCountry.formatDocumentNumber,
        formatCellPhone: defaultCountry.formatCellPhone,
      });
    }
    const defaultRegion = regions[0];
    if (country && !region) {
      this.props.getCitiesByRegionId(this.state.country, defaultRegion.id);
      this.setState({ region: defaultRegion.id, city: null });
    }
    const defaultCity = cities[0];
    if (country && region && !city) {
      this.setState({ city: defaultCity.id });
    }
  }

  transformLocation = (data) => {
    return data
      ? data.map((location) => ({
          value: location.id,
          label: location.name,
          data: location.data,
        }))
      : [];
  };

  onChangeCountry = (event) => {
    const optionElement = event.target.childNodes[event.target.selectedIndex];
    const option = JSON.parse(optionElement.getAttribute("data-json"));
    this.setState({
      country: event.target.value,
      formatDocumentNumber: option.formatDocumentNumber,
      formatCellPhone: option.formatCellPhone,
      region: null,
      city: null,
    });
    this.props.getRegionsByCountryId(event.target.value);
  };

  onChangeRegion = (event) => {
    this.setState({ region: event.target.value });
    this.setState({ city: null });
    this.props.getCitiesByRegionId(this.state.country, event.target.value);
  };

  onChangeCity = (event) => {
    this.setState({ city: event.target.value });
  };

  sendRegister = (event) => {
    let form = {};
    const { t } = this.props;
  
    if (
      this.state.email === "" ||
      this.state.emailAgain === "" ||
      this.state.email !== this.state.emailAgain
    ) {
      form["emailAgain"] = true;
      return this.setState({
        isAlert: true,
        alert: {
          message: t("formRegister.validations.emailAgain"),
          type: "danger",
        },
      });
    }
    if (
      this.state.emailRepresentation === "" ||
      this.state.emailAgainLegal === "" ||
      this.state.emailRepresentation !== this.state.emailAgainLegal
    ) {
      form["emailAgainLegal"] = true;
      return this.setState({
        isAlert: true,
        alert: {
          message: t("formRegister.validations.emailAgainLegal"),
          type: "danger",
        },
      });
    }
    const prevalidation = [
      "legalRepresentation",
      "emailRepresentation",
      "documentNumberlegal",
    ].some((ele) => {
      if (this.state[ele] && this.state[ele] === "") {
        form[ele] = true;
        this.setState({
          isAlert: true,
          alert: {
            message: t(`formRegister.validations.legalRepresentation.${ele}`),
            type: "danger",
          },
        });
        return true;
      }
      return false;
    });
    if (prevalidation) return;
    Object.keys(this.state).forEach((property) => {
      if (
        property !== "formatDocumentNumber" &&
        property !== "formatCellPhone"
      ) {
        let validation = this.state[property].length === 0 ? true : false;
        if (
          property === "verificationDigit" &&
          !this.state.isVerificationDigit
        ) {
          validation = false;
        }
        form[property] = validation;
      }
    });
    this.setState({
      form,
      isAlert: false,
      alert: {
        message: "",
        type: "",
      },
    });
    if (this.state.isVerificationDigit && this.state.verificationDigit === "") {
      return this.setState({
        isAlert: true,
        alert: {
          message: t("formRegister.validations.verificationDigit"),
          type: "danger",
        },
      });
    }
    if (this.state.isAuthorized === false) {
      return this.setState({
        isAlert: true,
        alert: {
          message: t("formRegister.validations.personalData"),
          type: "danger",
        },
      });
    }

    if (this.state.isTerms === false) {
      return this.setState({
        isAlert: true,
        alert: {
          message: t("formRegister.validations.termsConditions"),
          type: "danger",
        },
      });
    }
    // validation documents register

    let validationDocuments =
      this.state.documentsRegisterType &&
      this.state.documentsRegisterType.every((ele) => {
        if (parseInt(ele.type_account) === parseInt(this.state.typeAccount)) {
          if (!this.state.documentsRegister[ele.type_account]) return false;
          if (
            !Object.keys(
              this.state.documentsRegister[ele.type_account]
            ).includes(ele.type_document)
          )
            return false;
          if (
            !this.state.documentsRegister[ele.type_account] &&
            !this.state.documentsRegister[ele.type_account][ele.type_document]
          )
            return false;
          if (
            this.state.documentsRegister[ele.type_account][ele.type_document]
              .length === 0
          )
            return false;
          return true;
        } else return true;
      });

    if (validationDocuments === false) {
      return this.setState({
        isAlert: true,
        alert: {
          message: t("formRegister.validations.documents"),
          type: "danger",
        },
      });
    }

    if (this.formValid()) {
      this.setState({ loadPage: true });

      const documentsRegister = {};
      this.state.documentsRegister[this.state.typeAccount] &&
        Object.keys(this.state.documentsRegister[this.state.typeAccount]).map(
          (typeDocument) => {
            if (!documentsRegister[typeDocument])
              documentsRegister[typeDocument] = [];
            documentsRegister[typeDocument] = this.state.documentsRegister[
              this.state.typeAccount
            ][typeDocument].map((item) => {
              try {
                if (Object.keys(item.getMetadata()).length > 0) {
                  return {
                    ...item.getMetadata(),
                  };
                } else {
                  return JSON.parse(item.serverId);
                }
              } catch (e) {
                if (item.options && item.options.metadata) {
                  return {
                    ...item.options.metadata,
                  };
                }
              }
            });
          }
        );
      
      const {
        address,
        city,
        region,
        country,
        documentNumber,
        documentNumberType,
        verificationDigit,
        contactName,
        email,
        emailAgain,
        form,
        isAuthorized,
        name,
        phone,
        phoneCode,
        recaptchaValue,
        typeAccount,
        typeDocument,
        legalRepresentation,
        emailRepresentation,
        legalPhone,
        documentNumberContact,
        typeDocumentContact,
        typeDocumentlegal,
        documentNumberlegal,
      } = this.state;

      setUser({
        address,
        city,
        region,
        country,
        documentNumber,
        verificationDigit,
        contactName,
        documentNumberContact,
        typeDocumentContact,
        email,
        emailAgain,
        legalPhone,
        form,
        isAuthorized,
        name,
        phone,
        phoneCode,
        recaptchaValue,
        typeAccount,
        typeDocument,
        documentsRegister,
        documentNumberType,
        legalRepresentation,
        emailRepresentation,
        typeDocumentlegal,
        documentNumberlegal,
      }).then((response) => {
        const typeAlert = response.status !== 1 ? "danger" : "success";
        window.grecaptcha.reset();
        this.setState({
          isAlert: true,
          loadPage: false,
          alert: {
            message: response.codeMessages,
            type: typeAlert,
          },
        });
        if (typeAlert === "success") {
          this.setState({
            clearForm: true,
            form: {},
            typeAccount:
              (this.state.typesAccounts.length > 0 &&
                this.state.typesAccounts[0].value) ||
              "",
            typeDocument: "1",
            documentsRegister: {},
            documentNumber: "",
            verificationDigit: "",
            contactName: "",
            name: "",
            email: "",
            emailAgain: "",
            legalPhone: "",
            address: "",
            phone: "",
            phoneCode: "",
            recaptchaValue: "",
            isAuthorized: true,
            legalRepresentation: "",
            emailRepresentation: "",
            documentNumberlegal: "",
            documentNumberContact: ""
          });
        }
      });
    }
  };

  onChange =
    (name, check = false) =>
    (event) => {
      const optionElement = event.target.childNodes[event.target.selectedIndex];
      let isVerificationDigit = this.state.isVerificationDigit;
      // if (name === "typeDocument") {
      //   const option = JSON.parse(optionElement.getAttribute("data-json"));
      //   isVerificationDigit = option.isVerificationDigit;
      // }
     

      if (!check) {
        let value = event.target.value.slice(0, 200);
        if (name === "documentNumber" || name === "documentNumberlegal" || name === "documentNumberContact") {
          
          value = value
          
        }
        // if (name === "documentNumberlegal") {
        //   value = value
        //     .toString()
        //     .replace(/[^0-9a-zA-Z]/g, "")
        //     .replace(/\B(?=(\w{3})+(?!\w))/g, ".");
        // }
        // if (name === "documentNumberContact") {
        //   value = value
        //     .toString()
        //     .replace(/[^0-9a-zA-Z]/g, "")
        //     .replace(/\B(?=(\w{3})+(?!\w))/g, ".");
        // }
        return this.setState({
          [name]: value,
          isVerificationDigit,
        });
      }
      return this.setState({
        [name]: event.target.checked,
      });
    };

  onChangeTypeAccount = (name) => (event) => {
    if (name === "typeAccount") {
      this.setState({
        documentsRegister: {},
      });
    }
    const optionElement = event.target.childNodes[event.target.selectedIndex];
    const option = JSON.parse(optionElement.getAttribute("data-json"));
    const alternativeText = option.alternativeText || "";
    return this.setState({
      [name]: event.target.value,
      alternativeText,
    });
  };

  onChangeReCAPTCHA = (value) => {
    this.setState({ recaptchaValue: value });
  };

  formValid = () => {
    const properties = Object.keys(this.state);
    for (let i = 0; i < properties.length; i++) {
      if (
        properties[i] !== "formatDocumentNumber" &&
        properties[i] !== "formatCellPhone"
      ) {
        if (
          properties[i] === "verificationDigit" &&
          this.state.isVerificationDigit &&
          this.state[properties[i]].trim().length === 0
        ) {
          return false;
        }
      }
    }
    return true;
  };

  handleClose = () => {
    this.setState({ showTerms: false, showHabeasData: false });
  };

  showTerms = () => {
    this.setState({ showTerms: true });
  };
  showHabeasData = () => {
    this.setState({ showHabeasData: true });
  };

  onChangePhone = (name) => (event) => {
    this.setState({
      [name]: event.target.value.replace(/\D/g, ""),
    });
  };

  render() {
    const { countries, regions, cities } = this.props;
    const {
      clearForm,
      loadPage,
      typesAccounts,
      showTerms,
      showHabeasData,
      isAlert,
      alert,
      commentDialog,
      typeAccount,
      alternativeText,
      name,
      email,
      emailAgain,
      emailAgainLegal,
      address,
      phone,
      phoneCode,
      country,
      region,
      city,
      termCondition,
      habeasData,
      contactName,
      formatDocumentNumber,
      formatCellPhone,
      modalMessages,
      message,
      comment,
      documentsRegister,
      isDisabledSave,
      documentsRegisterType,
      legalRepresentation,
      emailRepresentation,
      typeDocumentlegal,
      documentNumberlegal,
    } = this.state;
    const { t } = this.props;
    return (
      <Container style={{ marginTop: "170px", marginBottom: "5rem" }}>
        {document.body.classList.add("new-background")}
        <LoadPage load={loadPage} />
        <DialogComponent
          t={t}
          view={modalMessages || isAlert}
          alert={isAlert}
          alertMessage={alert && alert.message && alert.message}
          alertType={alert && alert.type && alert.type}
          tittle={t("confirmation")}
          message={message}
          comment={comment}
          commentDialog={commentDialog}
          setComment={(event) => this.setState({ commentDialog: event })}
          buttons={[
            {
              value: `${t("buttons.accept")}`,
              onclick: () => {
                
                this.setState({
                  pageLoad: false,
                  isAlert: false,
                });
                if(alert.type === "success") this.props.history.push("/")
              
              },
            },
          ]}
        />
        {/* <Card style={{ margin: "0px", maxWidth: "600px" }}>
          <Card.Header> */}
        <Row>
          <Grid container spacing={1} style={{ display: "flex" }}>
            <Col
              xs={12}
              sm={12}
              md={12}
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={sectIndicator}
                  alt="Indicator"
                  style={{ marginRight: "20px", width: "18%" }}
                />
                <h2
                  style={{
                    color: "#59B089",
                    fontSize: "2.8rem",
                    fontWeight: "700",
                    lineHeight: "98.5px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                >
                  {t("createAccount")}
                </h2>
              </div>
            </Col>{" "}
          </Grid>
        </Row>
        {/* </Card.Header>
          </Card> */}
        {/* <Container> */}
        <Row>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={12} md={8}>
              <Card
                className="custom-card custom-card-height"
                style={{ borderRadius: "30px" }}
              >
                <Card.Header className="card-custom-header">
                  <ListItemIcon style={{ alignItems: "center" }}>
                    {" "}
                    <img
                      className="animation1"
                      src={infoIcon}
                      style={{
                        width: "1.7rem",
                        height: "1.7rem",
                      }}
                    ></img>
                    <legend className="card-header-title">
                      {t("formRegister.companyInformation")}
                    </legend>
                  </ListItemIcon>

                  {
                    //<h2>{t("createAccount")}</h2>
                  }
                </Card.Header>

                <Card.Body>
                  <Form>
                    <fieldset>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridTypeAccount">
                          <Select
                            styles={false}
                            isInvalid={this.state.form.typeAccount}
                            options={typesAccounts}
                            label={t("formRegister.accountType")}
                            onChange={this.onChangeTypeAccount("typeAccount")}
                            value={typeAccount}
                          />

                          {alternativeText.length > 0 && (
                            <small>{alternativeText}</small>
                          )}
                        </Form.Group>
                      </Form.Row>
                      <Form.Group controlId="formGridName">
                        <Form.Label>{t("formRegister.fullname")}</Form.Label>
                        <Form.Control
                          isInvalid={this.state.form.name}
                          type="text"
                          placeholder={t("formRegister.fullname")}
                          onChange={this.onChange("name")}
                          value={name}
                          autoComplete="autoCompleteOff"
                        />
                      </Form.Group>
                      <Form.Group controlId="formGridName">
                        <Form.Label>{t("formRegister.ubication")}</Form.Label>
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridCity">
                          <Select
                            isInvalid={this.state.form.country}
                            options={this.transformLocation(
                              countries.map((e) => ({
                                ...e,
                                data: {
                                  formatDocumentNumber: e.formatDocumentNumber,
                                  formatCellPhone: e.formatCellPhone,
                                },
                              }))
                            )}
                            label={t("country")}
                            onChange={this.onChangeCountry}
                            value={country}
                          />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridRegion">
                          <Select
                            isInvalid={this.state.form.region}
                            options={this.transformLocation(regions)}
                            label={t("region")}
                            onChange={this.onChangeRegion}
                            value={region}
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridZip">
                          <Select
                            isInvalid={this.state.form.city}
                            options={this.transformLocation(cities)}
                            label={t("city")}
                            onChange={this.onChangeCity}
                            value={city}
                          />
                        </Form.Group>
                      </Form.Row>
                      {countries.length > 0 && (
                        <>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress">
                              <Form.Label>
                                {t("formRegister.address")}
                              </Form.Label>
                              <Form.Control
                                isInvalid={this.state.form.address}
                                placeholder={t("formRegister.address")}
                                onChange={this.onChange("address")}
                                value={address}
                                autoComplete="autoCompleteOff"
                              />
                            </Form.Group>
                          </Form.Row>
                        
                          <Form.Group>
                            <Row>
                              <DocumentNumber
                                countryId={country}
                                representation={false}
                                typeDocumentName="typeDocument"
                                documentNumberName="documentNumberType"
                                verificationDigitName="verificationDigit"
                                classDocumentNumber={{
                                  style: {
                                    marginTop: "1rem",
                                    marginBottom: -14,
                                  },
                                }}
                                formValidation={this.state.form}
                                translation={t}
                                formOnChange={this.onChange}
                                formatDocumentNumber={formatDocumentNumber}
                                clearForm={clearForm}
                              />
                            </Row>
                          </Form.Group>
                        </>
                      )}
                    </fieldset>
                  </Form>
                </Card.Body>
              </Card>
            </Grid>
            {/* Legal representative */}
            <Grid item xs={12} sm={12} md={8} style={{ marginTop: "2.5rem" }}>
              <Card
                className="custom-card custom-card-height"
                style={{ borderRadius: "30px" }}
              >
                <Card.Header className="card-custom-header">
                  <ListItemIcon style={{ alignItems: "center" }}>
                    {" "}
                    <img
                      className="animation1"
                      src={userPaperIcon}
                      style={{ width: "1.8rem", height: "1.8rem" }}
                    ></img>
                    <legend className="card-header-title">
                      {t("formRegister.legalRepresentationInformation")}
                    </legend>
                  </ListItemIcon>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <fieldset>
                      <Form.Group controlId="legalRepresentation">
                        <Form.Label>
                          {t("formRegister.legalRepresentation")}
                        </Form.Label>
                        <Form.Control
                          isInvalid={this.state.form.contactName}
                          type="text"
                          placeholder={t("formRegister.legalRepresentation")}
                          onChange={this.onChange("legalRepresentation")}
                          value={legalRepresentation}
                          autoComplete="autoCompleteOff"
                        />
                      </Form.Group>

                      <Form.Row></Form.Row>
                      {countries.length > 0 && (
                        <Form.Group>
                          <Row>
                            <DocumentNumber
                              countryId={country}
                              representation={true}
                              typeDocumentName="typeDocumentlegal"
                              documentNumberName="documentNumberlegal"
                              verificationDigitName="verificationDigitlegal"
                              classDocumentNumber={{
                                style: { marginTop: "1rem", marginBottom: -14 },
                              }}
                              formValidation={this.state.form}
                              translation={t}
                              formOnChange={this.onChange}
                              formatDocumentNumber={formatDocumentNumber}
                              clearForm={clearForm}
                            />
                          </Row>
                        </Form.Group>
                      )}
                      <Form.Group controlId="formGridEmailRepresentation">
                        <Form.Label>
                          {t("formRegister.emailRepresentation")}
                        </Form.Label>
                        <Form.Control
                          isInvalid={this.state.form.emailRepresentation}
                          type="email"
                          placeholder={t("formRegister.emailRepresentation")}
                          onChange={this.onChange("emailRepresentation")}
                          value={emailRepresentation}
                          autoComplete="autoCompleteOff"
                        />
                      </Form.Group>
                      <Form.Group controlId="formGridEmail">
                      <Form.Label>{t("formRegister.emailAgainLegal")}</Form.Label>
                      <Form.Control
                        isInvalid={this.state.form.emailAgain}
                        type="emailAgainLegal"
                        placeholder={t("formRegister.emailAgainLegal")}
                        onChange={this.onChange("emailAgainLegal")}
                        value={emailAgainLegal}
                        autoComplete="autoCompleteOff"
                      />
                    </Form.Group>
                      <Form.Group controlId="formPhone">
                        <Form.Label>Teléfono/Celular</Form.Label>

                      <div> 
                        <PhoneInput
                          defaultCountry="co"
                          value={this.state.legalPhone}
                          placeholder="Ingrese su número de teléfono"
                          onChange={(value) => {
                            const formattedValue = value;
                            this.setState((prevState) => ({
                              ...prevState,
                              legalPhone: formattedValue,
                            }));
                          }}
                          style={{ minWidth: "50%" }}
                        />
                      </div>
                      </Form.Group>
                    </fieldset>
                  </Form>
                </Card.Body>
              </Card>
            </Grid>
            {/* Contact information */}
            <Grid item xs={12} sm={12} md={8} style={{ marginTop: "2.5rem" }}>
              <Card
                className="custom-card custom-card-height"
                style={{ borderRadius: "30px" }}
              >
                <Card.Header className="card-custom-header">
                  <ListItemIcon style={{ alignItems: "center" }}>
                    {" "}
                    <img
                      className="animation1"
                      src={accountAdminIcon}
                      style={{ width: "1.8rem", height: "1.8rem" }}
                    ></img>
                    <legend className="card-header-title">
                      {t("formRegister.contactInformation")}
                    </legend>
                  </ListItemIcon>
                </Card.Header>
                <Card.Body>
                  <fieldset>
                    <Form.Group controlId="formGridContactName">
                      <Form.Label>{t("formRegister.contactName")}</Form.Label>
                      <Form.Control
                        isInvalid={this.state.form.contactName}
                        type="text"
                        placeholder={t("formRegister.contactName")}
                        onChange={this.onChange("contactName")}
                        value={contactName}
                        autoComplete="autoCompleteOff"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Row>
                        <DocumentNumber
                          countryId={country}
                          representation={true}
                          typeDocumentName="typeDocumentContact"
                          documentNumberName="documentNumberContact"
                          classDocumentNumber={{
                            style: { marginTop: "1rem", marginBottom: -14 },
                          }}
                          formValidation={this.state.form}
                          translation={t}
                          formOnChange={this.onChange}
                          formatDocumentNumber={formatDocumentNumber}
                          clearForm={clearForm}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                      <Form.Label style={{ paddingRight: 10 }}>
                        {t("formRegister.email")}
                      </Form.Label>
                      <Helper text={"helper.register.email"} />
                      <Form.Control
                        isInvalid={this.state.form.email}
                        type="email"
                        placeholder={t("formRegister.email")}
                        onChange={this.onChange("email")}
                        value={email}
                        autoComplete="autoCompleteOff"
                      />
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>{t("formRegister.emailAgain")}</Form.Label>
                      <Form.Control
                        isInvalid={this.state.form.emailAgain}
                        type="emailAgain"
                        placeholder={t("formRegister.emailAgain")}
                        onChange={this.onChange("emailAgain")}
                        value={emailAgain}
                        autoComplete="autoCompleteOff"
                      />
                    </Form.Group>
                    <Form.Group controlId="formGridCellPhone">
                      <Form.Label>{t("formRegister.cellPhone")}</Form.Label>
                      <div>
                        <PhoneInput
                          defaultCountry="co"
                          value={phone}
                          placeholder={t("formRegister.cellPhone")}
                          onChange={(value) => {
                            const formattedValue = value;
                            this.setState((prevState) => ({
                              ...prevState,
                              phone: formattedValue,
                            }));
                          }}
                          style={{ minWidth: "515px" }}
                        />

                        {/* <NumberFormat
                              style={{
                                maxWidth: "30%",
                                display: "inline-block",
                                marginRight: "2%",
                              }}
                              className="form-control"
                              format="+###"
                              placeholder={t("code")}
                              value={phoneCode}
                              onValueChange={(values) => {
                                const { formattedValue } = values;
                                this.setState((prevState) => ({
                                  ...prevState,
                                  phoneCode: formattedValue,
                                }));
                              }}
                            /> */}
                        {/* <NumberFormat
                              className="form-control"
                              style={{ maxWidth: "50%", display: "inline-block" }}
                              format={formatCellPhone}
                              placeholder={t("formRegister.cellPhone")}
                              mask="_"
                              value={phone}
                              onValueChange={(values) => {
                                const { value } = values;
                                this.setState((prevState) => ({
                                  ...prevState,
                                  phone: value,
                                }));
                              }}
                            /> */}
                      </div>
                    </Form.Group>
                  </fieldset>
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
        </Row>
        <Row>
          {/* Documents */}
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={12} md={8} style={{ marginTop: "2.5rem" }}>
              <Card
                className="custom-card"
                style={{ minHeight: "None", marginTop: "20px" }}
              >
                <Card.Header className="card-custom-header">
                  <ListItemIcon style={{ alignItems: "center" }}>
                    {" "}
                    <img
                      className="animation1"
                      src={paperDocumentIcon}
                      style={{ width: "1.8rem", height: "1.8rem" }}
                    ></img>
                    <legend className="card-header-title">
                      {t("formRegister.documents")}
                    </legend>
                  </ListItemIcon>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Grid container spacing={2}>
                      {documentsRegisterType &&
                        documentsRegisterType
                          .filter(
                            (ele) =>
                              parseInt(ele.type_account) ===
                              parseInt(typeAccount)
                          )
                          .map((row, index) => (
                            <React.Fragment key={row.id}>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Group
                                  controlId="formGridAddress"
                                  style={{ margin: 0 }}
                                >
                                  <Form.Label style={{ margin: 0 }}>
                                    {row.description}
                                  </Form.Label>
                                </Form.Group>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Form.Group
                                  style={{ margin: 0 }}
                                  controlId="formGridAddress"
                                >
                                  <div style={{ width: "20rem" }}>
                                    <FilePond
                                      key={row.id}
                                      style={{
                                        background: "#fff",
                                        borderRadius: 10,
                                      }}
                                      acceptedFileTypes={
                                        row.accepted_file_types
                                          ? [row.accepted_file_types]
                                          : [
                                              "application/doc",
                                              "application/pdf",
                                              "application/txt",
                                              ".docx",
                                            ]
                                      }
                                      ref={(ref) => (this.pond = ref)}
                                      allowImagePreview={
                                        row.image && row.image === 1
                                      }
                                      labelIdle={`<div style="display:flex, align-items:center">
                                          <img src="${fileUpload}" style="width: 3rem; height: 3rem; cursor: pointer;"/>
                                        </div>`}
                                      files={
                                        documentsRegister[row.type_account] &&
                                        documentsRegister[row.type_account][
                                          row.type_document
                                        ]
                                      }
                                      allowMultiple={
                                        row.multiple && row.multiple > 1
                                      }
                                      maxFiles={row.multiple ? row.multiple : 1}
                                      server={serverUploadConfig(
                                        "/api/file/upload",
                                        "register"
                                      )}
                                      onprocessfilestart={() =>
                                        this.setState({
                                          isDisabledSave: true,
                                        })
                                      }
                                      onprocessfile={() =>
                                        this.setState({
                                          isDisabledSave: false,
                                        })
                                      }
                                      onupdatefiles={(fileItems) => {
                                        let { documentsRegister } = this.state;
                                        if (
                                          !documentsRegister[row.type_account]
                                        )
                                          documentsRegister[row.type_account] =
                                            {};
                                        documentsRegister[row.type_account][
                                          row.type_document
                                        ] = fileItems.map((item) => item);
                                        this.setState({ documentsRegister });
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Grid>
                              {index < documentsRegisterType.length - 1 && (
                                <Grid item xs={12} style={{ paddingTop: 0 }}>
                                  <hr style={{ border: "1px solid #d1d0cf" }} />
                                </Grid>
                              )}
                            </React.Fragment>
                          ))}
                    </Grid>
                  </Form>
                  <Form>
                    <Form.Group
                      as={Col}
                      controlId="formGridAddress"
                      style={{ textAlign: "center" }}
                    >
                      <Form.Label
                        style={{
                          height: "35px",
                          marginTop: "2rem",
                          marginBottom: "2rem",
                          textAlign: "center",
                        }}
                      >
                        <ReCAPTCHA
                          sitekey="6LefRPYdAAAAANdQTSZ4NA-OV2BmT3dojawQ8-Gf"
                          onChange={this.onChangeReCAPTCHA}
                        />
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Col} style={{ textAlign: "center" }}>
                      <Form.Label onClick={() => {}}>
                        <Form.Check
                          type="checkbox"
                          inline
                          defaultChecked={false}
                          onChange={this.onChange("isAuthorized", true)}
                        />
                        {t("formRegister.personalData.part1")}
                        <span
                          onClick={this.showHabeasData}
                          style={{ color: "#00966C", cursor: "pointer" }}
                        >
                          {t("formRegister.personalData.part2")}
                        </span>
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} style={{ textAlign: "center" }}>
                      <Form.Label onClick={() => {}}>
                        <Form.Check
                          type="checkbox"
                          inline
                          defaultChecked={false}
                          onChange={this.onChange("isTerms", true)}
                        />
                        {t("formRegister.termsConditions.part1")}
                        <span
                          onClick={this.showTerms}
                          style={{ color: "#00966C", cursor: "pointer" }}
                        >
                          {t("formRegister.termsConditions.part2")}
                        </span>
                      </Form.Label>
                    </Form.Group>
                    {/* {isAlert && (
                <Error options={alert.message} value={alert.type}></Error>
              )} */}
                    <div style={{ textAlign: "center" }}>
                      <Button
                        style={{
                          ...platform.buttonSuccess,
                          borderRadius: "37px",
                        }}
                        type="button"
                        onClick={this.sendRegister}
                      >
                        {t("buttons.send")}
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
        </Row>
        {/* </Container> */}
        <Terms
          showTerms={showTerms}
          text={termCondition}
          handleClose={this.handleClose}
        />
        <HabeasData
          showTerms={showHabeasData}
          text={habeasData}
          handleClose={this.handleClose}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const mapStateToProps = ({ general }) => ({
  countries: general.countries,
  regions: general.regions,
  cities: general.cities,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
