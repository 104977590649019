import { GET, POST } from "./";
export const User = {
  get: () => GET(`/api/user/`),
  getUser: () => GET(`/api/user/getUser`),
  
  getByDocumentNumber: (documentNumber) =>
    GET(`/api/user/documentNumber/${documentNumber}`),
  create: (data) => POST(`/api/user`, data),
  login: (data) => POST(`/api/user/login`, data),
  loginForCompany: (data) => POST(`/api/user/loginForCompany`, data),
  passwordRecovery: (data) => POST(`/api/user/passwordRecovery`, data),
  updateCompanyInfo: (data) => POST(`/api/user/updateCompanyInfo`, data),
  validToken: (data) => POST(`/api/user/validToken`, data),
  updatePassword: (data) => POST(`/api/user/updatePassword`, data),
  getSearch: (data) => POST(`/api/user/search`, data),
  userChangeActive: (userId, data) => POST(`/api/user/active/${userId}`, data),
  changePermit: (userId, data) => POST(`/api/user/permit/${userId}`, data),
  userExchange: (data) => POST(`/api/user/exchangeUser`, data),
  createByCompany: (data) => POST(`/api/user/createByCompany`, data),
};
