function validateToken(response) {
  if (response.status === 401) {
    //Token invalido, se borra el token del localStorage
    localStorage.removeItem("KarbonoaTOKEN");
    // Se redireje la pagina al login
    return (window.location.href = "/login");
  }
  return response;
}

function request(url, options) {
  // const urlEnd = `https://api-front.ecoregistry.io${url}`;
  //const urlEnd = `https://api-dev.ecoregistry.io${url}`;
 const urlEnd = `${process.env.REACT_APP_API}${url}`;
  //const urlEnd = `http://localhost:3000${url}`;

  return fetch(urlEnd, options)
    .then(validateToken)
    .then((res, error) => {
     
      if (!res.headers.get('Content-Type').includes('application/json')) {
        
        return res}
      return res.json()})
    .catch((err) => err);
}

function getOptions(method, data = null) {
  const lng = window.localStorage.getItem("i18nextLng")
    ? window.localStorage.getItem("i18nextLng").split("-")[0]
    : "es";
  const options = {
    method,
    ...(data && { body: JSON.stringify(data) }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("KarbonoaTOKEN")}`,
      platform: "Karbonoa",
      lng: lng == "es" ?"es":"en" || "es",
    },
  };
  return options;
}

export const GET = (url) => {
  const options = getOptions("GET");
  return request(url, options);
};

export const POST = (url, data) => {
  const options = getOptions("POST", data);
  return request(url, options);
};

export const UPDATE = (url, data) => {
  const options = getOptions("PUT", data);
  return request(url, options);
};

export const DELETE = (url, data) => {
  const options = getOptions("DELETE", data);
  return request(url, options);
};
