import { GET, POST, UPDATE } from "./";

const apiGobalConsultation = {
    get: () => GET(`/api/gobalConsultation`),
    getComments: (data) => GET(`/api/gobalConsultation/comments/${data.project_id}`),
    getDocument: (data) => GET(`/api/gobalConsultation/download_document/${data.project_id}`),
    getCommentDocument: (data) => GET(`/api/gobalConsultation/download_comment_document/${data.project_id}/${data.comment_id}`),
    postCommentForm: (data) => POST('/api/gobalConsultation/comment', data),
    postRespnse: (data) => POST('/api/gobalConsultation/comment_respose', data),
}

export { apiGobalConsultation };