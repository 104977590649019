import * as React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import formatDate from '../../js/date';

function CardNotification({infoNotification, token}) {
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', border: '1px solid rgb(0, 150, 108)', borderRadius: '15px' }}>
            <div style={{ color: 'white', background: 'rgb(0, 150, 108)', borderRadius: '10px 10px 0 0', padding: 20 }}>
                <Typography variant="h5">Notifación</Typography>
            </div>
            <div style={{ padding: 20 }}>
                <Grid container spacing={0}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column' }}>
                        <p style={{ fontSize: 20, fontWeight: 700 }}>Titulo: <span style={{ fontWeight: 400 }}>{infoNotification.title}</span> </p>
                        <p style={{ fontSize: 20, fontWeight: 700 }}>Compañia: <span style={{ fontWeight: 400 }}>{infoNotification.name_company}</span> </p>
                        <p style={{ fontSize: 20, fontWeight: 700 }}>Nombre: <span style={{ fontWeight: 400 }}>{infoNotification.name_action}</span> </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p style={{ fontSize: 17, fontWeight: 700 }}>Fecha: {infoNotification.created_at
                            ? formatDate.formatDateShort(infoNotification.created_at)
                            : ''}
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} style={{ padding: 17 }}>
                        <p style={{ marginTop: 25, fontSize: 20, fontWeight: 700 }}>Descripción</p>
                        <p>{infoNotification.description}</p>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 17 }}>
                        {
                            token.typeAccount === 'owner' &&
                            <Link to={`/projects/${infoNotification.id_action}`}>
                                <Button variant="contained" color='success' style={{ marginRight: 13, background: 'rgb(0, 150, 108)' }}>Ver</Button>
                            </Link>
                        }

                        {
                            token.typeAccount === 'certifier' &&
                            <Link to={`/projects/${infoNotification.id_action}`}>
                                <Button variant="contained" color='success' style={{ marginRight: 13, background: 'rgb(0, 150, 108)' }}>Aprobar</Button>
                            </Link>
                        }

                        {/* <Button variant="contained" color='error'>Rechazar</Button> */}
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}

export default CardNotification;