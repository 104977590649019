import React, { Component, Fragment } from "react";
import { getCountries } from "../../actions/country";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import sectIndicator from "../../images/sect_indicator.svg";

// Material table
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import DialogModalGlobal from "./DialogModalGlobal";

import LoadPage from "../Load/LoadPage";

import NumberFormat from "react-number-format";

import multipleUsersIcon from "../../images/multiple-users.png";

import {
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Dropdown,
} from "react-bootstrap";

import { getWithdrawalInformation } from "../../actions/project";

import { bindActionCreators } from "redux";

import { platform } from "../../Style/index";

const actions = {
  getCountries,
};

class ProjectVintageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoad: true,
      projectSerialYear: [],
      sortConfig: { key: null, direction: "asc" },
      modalCommentsGlobal: false,
      comment: [],
    };
  }

  sortData = (key) => {
    const { projectSerialYear, sortConfig } = this.state;
    let direction = "asc";

    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedSerial = [...projectSerialYear].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      if (key === "ownerAvailable") {
        const diffA = Number(a.available) - Number(a.otherAccounts);
        const diffB = Number(b.available) - Number(b.otherAccounts);

        return direction === "asc" ? diffA - diffB : diffB - diffA;
      }

      if (typeof valueA === "number" && typeof valueB === "number") {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === "boolean" && typeof valueB === "boolean") {
        return direction === "asc"
          ? valueA === valueB
            ? 0
            : valueA
            ? -1
            : 1
          : valueA === valueB
          ? 0
          : valueA
          ? 1
          : -1;
      }

      return 0;
    });

    this.setState({
      sortConfig: { key, direction },
      projectSerialYear: sortedSerial,
    });
  };

  componentDidMount() {
    getWithdrawalInformation().then((response) => {
      if (response.status !== 1) {
        return this.props.history.push("/404");
      }

      this.setState({
        pageLoad: false,
        projectSerialYear: response.projectSerialYear,
      });
    });
  }

  renderSortableHeader = (key, label) => {
    const { sortConfig } = this.state;
    return (
      <TableCell
        className="colcx-table-cell-header"
        style={{
          verticalAlign: "middle",
          width: 150,
          cursor: "pointer",
        }}
        onClick={() => this.sortData(key)}
      >
        {label}{" "}
        {sortConfig.key === key ? (
          sortConfig.direction === "asc" ? (
            <FontAwesomeIcon icon={faSortUp} />
          ) : (
            <FontAwesomeIcon icon={faSortDown} />
          )
        ) : (
          <FontAwesomeIcon icon={faSort} />
        )}
      </TableCell>
    );
  };

  render() {
    const { t, token } = this.props;
    const { projectSerialYear, pageLoad, comment, modalCommentsGlobal } =
      this.state;
    return (
      <div>
        {document.body.classList.add("new-background")}
        <Container
          style={{
            minHeight: "100vh",
            marginTop: token ? "10px" : "150px",
          }}
        >
          <LoadPage load={pageLoad} />
          <DialogModalGlobal
            t={t}
            loadPage={(control) => {
              this.setState({ pageLoad: control });
            }}
            projectId={this.props.match.params.projectId}
            view={this.state.modalCommentsGlobal}
            downloadDocument={false}
            tittle={t("tableQtyOnAccounts.comments.tittle")}
            message={""}
            comment={""}
            columns={["broker", "date", "qty"].map((row) => ({
              label: `tableQtyOnAccounts.comments.${row}`,
              value: row,
            }))}
            consult={() => {}}
            commentDialog={"commentDialog"}
            setComment={comment}
            buttons={[
              {
                value: `${t("buttons.close")}`,
                onclick: () => {
                  this.setState({
                    modalCommentsGlobal: false,
                  });
                },
              },
            ]}
          />

<Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={sectIndicator}
                alt="Indicator"
                style={{ marginRight: "0px", width: "18%" }}
              />
              <h2
                style={{
                  color: "#59B089",
                  fontSize: "2.8rem",
                  fontWeight: "700",
                  lineHeight: "98.5px",
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                {t("Vintages")}
              </h2>
            </div>
          </Col>
        </Row>

          <TableContainer
            sx={{ maxHeight: "70vh", borderRadius: "20px" }}
            style={{
              borderRadius: "20px",
              fontFamily: "Montserrat",
              background: "white",
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderRadius: "20px",
                fontFamily: "Montserrat",
                background: "white",
              }}
            >
              <TableHead className="colcx-table">
                {" "}
                <TableRow className="colcx-table">
                  <TableCell colspan={11} className="colcx-table-cell-header">
                    Vintages
                  </TableCell>
                </TableRow>
                <TableRow className="colcx-table">
                  <TableCell colspan={2} className="colcx-table-cell-header">
                    {" "}
                  </TableCell>
                  <TableCell
                    colspan={8}
                    style={{
                      borderRight: "1px solid",
                      borderLeft: "1px solid",
                    }}
                    className="colcx-table-cell-header"
                  >
                    {" "}
                    {t("certificates")}
                  </TableCell>
                  <TableCell colspan={1} className="colcx-table-cell-header">
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow className="colcx-table">
                  {this.renderSortableHeader("year", "Año Vintage")}
                  {this.renderSortableHeader("serial", "Cod. Vintage")}
                  {this.renderSortableHeader("net", `${t("Totales")}`)}
                  {this.renderSortableHeader("buffer", `${t("Buffer")}`)}
                  {this.renderSortableHeader(
                    "initial",
                    `${t("bonusCertification")}`
                  )}
                  <TableCell
                    className="colcx-table-cell-header"
                    style={{ verticalAlign: "middle", width: "10%" }}
                  >
                    {t("Retenidos")}
                  </TableCell>
                  {this.renderSortableHeader(
                    "otherAccounts",
                    `${t("bonusOtherAccounts")}`
                  )}
                  {this.renderSortableHeader(
                    "cancelled",
                    `${t("bonusCancellation")}`
                  )}
                  {this.renderSortableHeader(
                    "available",
                    `${t("Disponibles totales del proyecto")}`
                  )}
                  {this.renderSortableHeader(
                    "ownerAvailable",
                    `${t("Disponibles del titular del proyecto")}`
                  )}

                  <TableCell
                    className="colcx-table-cell-header"
                    style={{ verticalAlign: "middle", width: "10%" }}
                  >
                    {t("options")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectSerialYear &&
                  projectSerialYear.map((row, key) => {
                    return (
                      <TableRow>
                        <TableCell className="colcx-table-cell-info">
                          {row.year}
                        </TableCell>
                        <TableCell className="colcx-table-cell-info">
                          {row.serial}
                        </TableCell>

                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={row.net}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>
                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={row.buffer}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>

                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={row.initial}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>
                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={0}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>

                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={row.otherAccounts}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>

                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={row.cancelled}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>
                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={row.available}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>
                        <TableCell className="colcx-table-cell-info">
                          <NumberFormat
                            value={
                              Number(row.available) - Number(row.otherAccounts)
                            }
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>
                        <TableCell className="colcx-table-cell-info">
                          <Button
                            size="sm"
                            style={{
                              ...platform.buttonSuccess,
                              fontSize: "0.875rem",
                              padding: "7px, 22px, 7px, 22px",
                              borderRadius: "37px",
                              borderColor: "inherit",
                            }}
                            // variant={
                            //   certificated.isCanceled ? "secondary" : "info"
                            // }
                            title={t("buttons.preview")}
                            onClick={() =>
                              this.setState({
                                modalCommentsGlobal: true,
                                comment: row.otherAccountsDetails,
                              })
                            }
                          >
                            <img
                              style={{ width: "15px", height: "15px" }}
                              src={multipleUsersIcon}
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const mapStateToProps = ({ user, general }) => ({
  token: user.token,
  countries: general.countries,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProjectVintageList)
);
