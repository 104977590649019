import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import LoupeIcon from "@mui/icons-material/Loupe";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PublicIcon from "@mui/icons-material/Public";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import { links } from "./../../conf";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styledLink = {
  color: "inherit",
  textDecoration: "none",
};

export default function HeaderPhone(props) {
  const [open, setOpen] = React.useState(false);
  const [openNav, setOpenNav] = React.useState({});
  const language = window.localStorage.getItem("i18nextLng") || "es";
  const [languageDefault, setLanguageDefault] = React.useState(
    language || "es"
  );

  const { t } = useTranslation();

  const changeLanguage = (lng, reload = true) => {
    const i18n = props.i18n;
    i18n.changeLanguage(lng);
    if (reload) {
      window.location.reload();
    }
  };

  const handleClick = (link) => {
    setOpenNav((prevState) => ({
      ...prevState,
      [link]: !prevState[link],
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Nav = () => {
    return (
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: "auto", bottom: 0, background: "#f0f1f9" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleClickOpen}
          >
            <MenuIcon style={{ color: "#59B089" }} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />

          <Link to="/register">
            <Tooltip title={t("register")} arrow>
              <LoupeIcon style={{ marginRight: 10, color: "#59B089" }} />
              <></>
            </Tooltip>
          </Link>

          <Link to="/login">
            <Tooltip title={t("login")} arrow>
              <PersonAddIcon style={{ marginRight: 20, color: "#59B089" }} />
            </Tooltip>
          </Link>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <React.Fragment>
      <Nav />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ background: "#3D7A5E" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Menu
            </Typography>
            <ReactFlagsSelect
              defaultCountry={
                languageDefault === "en"
                  ? "US"
                  : languageDefault === "es"
                  ? "ES"
                  : "ES"
              }
              placeholder="..."
              style={{ color: "white", display: "inline" }}
              countries={["US", "ES"]}
              onSelect={(e) => {
                setLanguageDefault(
                  e === "US" || e === "en"
                    ? "en"
                    : e === "es" || e === "EN"
                    ? "es"
                    : "es"
                );
                changeLanguage(
                  e === "US" || e === "en"
                    ? "en"
                    : e === "es" || e === "EN"
                    ? "es"
                    : "es"
                );
              }}
              customLabels={{ US: "English", ES: "Español" }}
              alignOptionsToRight={true}
            />
          </Toolbar>
        </AppBar>
        <List>
          <Link
            to="/global-consultation"
            style={styledLink}
            onClick={() => setOpen(false)}
          >
            <ListItemButton>
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText primary="Consulta global" />
            </ListItemButton>
          </Link>
          <Divider />
          {links(props.token).map((link) => (
            <React.Fragment key={link.to}>
              {link.childNodes && link.childNodes.length > 0 ? (
                <>
                  <ListItemButton onClick={() => handleClick(link.to)}>
                    <ListItemIcon>
                      {link.icon === "help" && <DeviceUnknownIcon />}
                    </ListItemIcon>
                    <ListItemText primary={t(`${link.label}`)} />
                    {openNav[link.to] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openNav[link.to]} timeout="auto" unmountOnExit>
                    {link.childNodes.map((linkSub) => (
                      <List component="div" disablePadding key={linkSub.to}>
                        <Link
                          to={linkSub.to}
                          style={styledLink}
                          onClick={() => setOpen(false)}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              {linkSub.icon === "group" && <PersonIcon />}
                              {linkSub.icon === "personAdd" && (
                                <PersonAddIcon />
                              )}
                              {linkSub.icon === "work" && <VerifiedUserIcon />}
                            </ListItemIcon>
                            <ListItemText primary={t(`${linkSub.label}`)} />
                          </ListItemButton>
                        </Link>
                      </List>
                    ))}
                  </Collapse>
                  <Divider />
                </>
              ) : (
                <>
                  <Link
                    to={link.to}
                    style={styledLink}
                    onClick={() => setOpen(false)}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        {link.icon === "list" && <ListAltIcon />}
                        {link.icon === "contact" && <ContactMailIcon />}
                      </ListItemIcon>
                      <ListItemText primary={t(`${link.label}`)} />
                    </ListItemButton>
                  </Link>
                  <Divider />
                </>
              )}
            </React.Fragment>
          ))}
        </List>
      </Dialog>
    </React.Fragment>
  );
}
