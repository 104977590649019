import React from "react";
import { Modal } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";

const HabeasData = ({ showTerms, handleClose, text }) => (
  <Modal show={showTerms} onHide={handleClose} size="lg" style={{ 
    // minWidth:"200%",
    // width:"200%"
    }}>
    <Modal.Body style={{display:"flex"} }>
   
     
      <embed 
      src="https://ecoregistrystoragedev.blob.core.windows.net/projects/Karbonoa_Terminos_Condiciones_240612.pdf" 
      type="application/pdf" 
      width="200%" height="700px" 
      />
  
    </Modal.Body>
  </Modal>
);

export default HabeasData;
