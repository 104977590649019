import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import CustomTextFild from "../custom/CustomTextFied";
import CustomAlert from "../custom/CustomAlert";
import { inputsComentsForm } from "../../constants/constantsGlobalConsultation";
import validationUtils from "../../js/validators";
import actionGlobalConsultation from "../../actions/gobalConsultation";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalRespondComment({ idComment, idProject, openLoader, setOpenLoader, stateComments }) {
    const [open, setOpen] = React.useState(false);
    const [formValues, setFormValues] = useState({ name: '', document_number: '', email: '', company: '', message: '' });
    const [errors, setErrors] = useState({ name: false, document_number: false, email: false, company: false, message: false });
    const [allNews, setAllNews] = useState([]);
    const { t } = useTranslation();

    const actualizarEtiquetas = (etiquetas) => {
        return etiquetas.map((etiqueta) => {
            const nuevaEtiqueta =  t(`globalConsultation.initiatives.labelsInputs.${etiqueta.name.toLowerCase()}`);
            return { ...etiqueta, label: nuevaEtiqueta };
        });
    };

    const nuevasEtiquetas = actualizarEtiquetas(inputsComentsForm);

    const resetValues = () => {
        setFormValues({ name: '', document_number: '', email: '', company: '', message: '' });
        setErrors({ name: false, document_number: false, email: false, company: false, message: false });
        // setDcument([]);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const sendCommentResponse = async (values) => {

        const data = {
            id_comment: idComment,
            project_id: idProject,
            name: formValues.name,
            document_number: formValues.document_number,
            email: formValues.email,
            company: formValues.company,
            message: formValues.message,
        }

        const comments = await actionGlobalConsultation.postCommetResponse(data);
        return comments;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationResult = validationUtils.validateCommentsForm(formValues, setErrors);

            if (validationResult.state !== true) {
           
                handleClose();
                setOpenLoader(true);
                const request = await sendCommentResponse(formValues);
       

                if (request.status === 200) {
                    stateComments(request.response);
                    setOpenLoader(false);
                    resetValues();
                } else if (request.status === 500) {
                    setOpenLoader(false);
                }

            } else {
                const news = validationResult.news;

                setAllNews([
                    {
                        name: 'document',
                        message: `Por favor, asegúrate de ingresar todos los datos obligatorios marcados con (*) `
                    }]);
                // setAllNews(news);
                const updatedErrors = { ...errors };

                // Establecer todas las claves a false
                Object.keys(updatedErrors).forEach(key => {
                    updatedErrors[key] = false;
                });

                // // Iterar sobre las noticias y actualizar las claves correspondientes
                news.forEach(notification => {
                    updatedErrors[notification.name] = true;
                });

                setErrors(updatedErrors);
            }
        } catch (error) {
            console.error('Error al manejar el formulario:', error);
            // Puedes manejar el error de alguna manera, por ejemplo, mostrar un mensaje al usuario.
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={t("globalConsultation.comments.responseComment")} placement="top">
                <IconButton edge="end" aria-label="comments" style={{ marginRight: 20 }} onClick={handleClickOpen}>
                    <CommentIcon style={{ color: 'rgb(0, 150, 108)', fontSize: 30 }} />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{t("globalConsultation.comments.responseComment")}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container spacing={3} direction="row" justifyContent="start" alignItems="center" alignContent="center" wrap="wrap">
                                {nuevasEtiquetas.map((input) => (
                                    <Grid item md={input.column} key={input.id}>
                                        <CustomTextFild
                                            label={`${input.label}*`}
                                            name={input.name}
                                            type={input.type}
                                            value={formValues[input.name]}
                                            change={handleChange}
                                            fullWidth={input.fullWidth}
                                            multiline={input.multiline}
                                            rows={input.rows}
                                            error={errors[input.name]}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t("buttons.cancel")}</Button>
                        <Button type="submit">{t("buttons.send")}</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <CustomAlert info={allNews} variant={'error'} />
        </React.Fragment>
    );
}
