import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background: #59B089;
  padding: 20px 0 30px;  
  color: rgba(255,255,255,.8);
  border-top: 2px solid #55A79A;

  .container{
    max-width: 100%;
    h4{
      font-size: 1.25rem;
      margin-bottom: 5px;
    }
    address ul{
      padding: 0px;
      margin: 0px;
    }
    address ul li{
      display: inline-block;
      vertical-aling: top;
      width: 100%;
    }
  }
`;