import creditConversion from "../api/creditConversion";

export const SET_ISCONVERTEDFROM = "SET_ISCONVERTEDFROM";
const SETISCONVERTEDFROM = (payload) => ({
  type: SET_ISCONVERTEDFROM,
  payload,
});

export const getCreditConversion = (projectId) => {
  return creditConversion
    .getByProjectId(projectId)
    .then((response) => response);
};

export const downloadFile = (data) => {
  return creditConversion.downloadFile(data).then((response) => response);
};

export const saveCreditConversion = (data) => {
  return creditConversion.save(data).then((response) => response);
};

export const setIsConvertedFrom = (isConvertedFrom) => {
  return (dispatch) => {
    dispatch(SETISCONVERTEDFROM(isConvertedFrom));
  };
};
