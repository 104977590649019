import { SET_ISCONVERTEDFROM } from "../actions/creditConversion";

const initialState = {
  isConvertedFrom: null,
};

export const creditConversion = (state = initialState, action) => {
  switch (action.type) {
    case SET_ISCONVERTEDFROM: {
      return {
        ...state,
        isConvertedFrom: action.payload,
      };
    }
    default:
      return state;
  }
};
