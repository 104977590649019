import React, { Component, Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from "jwt-decode";
import { Col, Row } from "react-bootstrap";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { loginForCompany } from "../../actions/user";
import { pageInit } from "../../conf/";
import Select from "../Form/Select";
import { links, myAccount, myTrading } from "./../../conf";
//import { links, myAccount } from "./../../conf";
import iconMyAccount from "./../../images/my-account-icon.png";
import { Grid, Box, CardMedia } from "@mui/material";
import worldIcon from "./../../images/worldIcon.svg";

import IconNotifications from "../Notifications/IconNotification";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import Tooltip from "@mui/material/Tooltip";
import LoupeIcon from "@mui/icons-material/Loupe";
import CottageIcon from "@mui/icons-material/Cottage";
import { NotificationContext } from "../../contexts/NotificationsContext";
import HeaderPhone from "./HeaderPhone";
// import LogoCompañia from "./../../../public/logo-colcx-white.svg";

import {
  BoxContainerPc,
  gridContainerNav,
  gridItem1nav,
  gridItem2nav,
  imgLogoNav,
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderContainerLanguage,
  StyledHeaderContainerLogo,
  StyledHeaderContainerMenuBar,
  StyledHeaderContainerNav,
  StyledHeaderContainerNavLi,
  StyledHeaderContainerNavLiMyAccount,
  StyledHeaderContainerNavLiSub,
  StyledHeaderContainerNavLiSubMyAccount,
  StyledHeaderUp,
} from "./StyledHeader";

class Header extends Component {
  static contextType = NotificationContext;

  constructor(props) {
    super(props);
    const language = window.localStorage.getItem("i18nextLng") || "es";
    // const { totalNoti } = this.context;
    this.state = {
      classStyledHeaderContainerNav: "hiden",
      company: null,
      pageLoad: false,
      isHidden: false,
      languageDefault: this.convertLanguageReactFlagsSelect(language),
      totalNotification: 0,
      selectedLink: "",
    };
    this.changeLanguage(language, false);
  }

  handleLinkClick = (linkTo) => {
    this.setState({ selectedLink: linkTo });
  };

  componentDidMount() {
    this.setState({ pageLoad: true });

    window.addEventListener("scroll", this.handleScroll);
    const { totalNoti, requestNotifications } = this.context;
    this.setState({
      totalNotification: totalNoti,
    });

    setTimeout(async () => {
      if (this.props.token) {
        const infoUsers = jwt_decode(this.props.token);
        if (infoUsers) {
          return this.setState({
            company: infoUsers.companyId,
            pageLoad: false,
          });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }


  handleScroll = () => {
    const threshold = 100; 
    if (window.scrollY > threshold) {
      this.setState({ isHidden: true });
    } else {
      this.setState({ isHidden: false });
    }
  };

  viewNav() {
    let { classStyledHeaderContainerNav } = this.state;
    classStyledHeaderContainerNav =
      classStyledHeaderContainerNav === "hiden" ? "show" : "hiden";
    this.setState({ classStyledHeaderContainerNav });
  }

  logout = () => {
    localStorage.removeItem("KarbonoaTOKEN");
    // Se redireje la pagina al login
    return (window.location.href = "/");
  };

  changeLanguage = (lng, reload = true) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
    if (reload) {
      window.location.reload();
    }
  };

  convertLanguageReactFlagsSelect = (lng) => {
    switch (lng) {
      case "en":
        return "en";
      case "es":
        return "es";
      default:
        return "es";
    }
  };

  convertLanguage = (lng) => {
    switch (lng) {
      case "en":
        return "en";
      case "es":
        return "es";
      default:
        return "es";
    }
  };

  transformSelect = (data) => {
    return data
      ? data.map((select) => ({
          value: select.company_id,
          label: select.company_company_id || select.description,
          data: select,
        }))
      : [];
  };

  onChange = (name) => (event) => {
    this.setState({ [name]: event.target.value, pageLoad: true });
    loginForCompany({ companyId: event.target.value })
      .then((response) => {
        if (response.status !== 1) {
          this.setState({
            pageLoad: false,
            isAlert: true,
            alert: {
              message: response.codeMessages,
              type: "danger",
            },
          });
        }
        localStorage.removeItem("KarbonoaTOKEN");
        this.props.getToken(null);
        // Se almacena el token en el local storage y a su vez en redux
        localStorage.setItem("KarbonoaTOKEN", response.token);
        this.props.getToken(response.token);

        const infoUser = jwt_decode(response.token);
        this.renderNavMyAccount(response.token);

        this.setState({ pageLoad: false });
        return this.props.history.push(pageInit[infoUser.typeAccount]);
      })
      .catch((err) => console.log(err));
  };

  renderNavMyAccount = (token) => {
    const nav = myAccount(token);
    const infoUser = jwt_decode(token);
    const { t } = this.props;
    const { totalNoti } = this.context;
    return nav
      .filter((e) => e.name === "myAccount")
      .map((e) => (
        <StyledHeaderContainerNavLiMyAccount
          key={e.name}
          style={{
            verticalAlign: "middle",
            background: "#153E35",
            width: "100%",
            marginTop: 80,
          }}
          id="navbarId"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconNotifications total={totalNoti} />

            <span className="nav-link dropdown" style={{ color: "white" }}>
              <img
                alt=""
                src={iconMyAccount}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {t(e.name)}
            </span>
          </div>
          <StyledHeaderContainerNavLiSubMyAccount>
            {e.name === "myAccount" && (
              <Fragment>
                <div className="info-user">
                  <div>{infoUser.name}</div>
                  <div>{infoUser.email}</div>
                </div>
              </Fragment>
            )}
            {e.links.map((nav) => (
              <Link key={nav.to} className="nav-link" to={nav.to}>
                {t(`${nav.label}`)}
              </Link>
            ))}
            {e.name === "myAccount" && (
              <Fragment>
                <Link className="nav-link" to="/my-company">
                  {t("myCompany")}
                </Link>
                <Link className="nav-link" to="/change-password">
                  {t("changePassword")}
                </Link>
                <Link className="nav-link" to="#" onClick={this.logout}>
                  {t("logout")}
                </Link>
              </Fragment>
            )}
          </StyledHeaderContainerNavLiSubMyAccount>
        </StyledHeaderContainerNavLiMyAccount>
      ));
  };

  renderNav = (token) => {
    const nav = myAccount(token);
    const infoUser = jwt_decode(token);
    const { t } = this.props;
    return nav
      .filter((e) => e.name !== "myAccount")
      .map((e) => (
        <StyledHeaderContainerNavLi key={e.name}>
          <span className="nav-link dropdown">{t(e.name)}</span>
          <StyledHeaderContainerNavLiSub>
            {e.name === "myAccount" && (
              <div className="info-user">
                <div>{infoUser.name}</div>
                <div>{infoUser.email}</div>
              </div>
            )}
            {e.name === "projects" && (
              <Link className="nav-link" to="/projects">
                {t("nav.allProjects")}
              </Link>
            )}
            {e.name === "positions" && infoUser.typeAccount !== "certifier" && (
              <Link className="nav-link" to="/my-positions">
                {t("nav.myPositions")}
              </Link>
            )}
            {/* {e.name === "Trading" && (
              <Link className="nav-link" to="/info-trading">
                {t("nav.trading")}
              </Link>
            )} */}

            {e.links.map((nav) => (
              <Link key={nav.to} className="nav-link" to={nav.to}>
                {t(`${nav.label}`)}
              </Link>
            ))}

            {e.name === "myAccount" && (
              <Fragment>
                <Link className="nav-link" to="/change-password">
                  {t("changePassword")}
                </Link>
                <Link className="nav-link" to="#" onClick={this.logout}>
                  {t("logout")}
                </Link>
              </Fragment>
            )}
          </StyledHeaderContainerNavLiSub>
        </StyledHeaderContainerNavLi>
      ));
  };

  render() {
    const {
      classStyledHeaderContainerNav,
      languageDefault,
      company,
      selectedLink,
    } = this.state;
    const { token } = this.props;
    const infoUsers = this.props.token ? jwt_decode(this.props.token) : {};
    // const [selectedLink, setSelectedLink] = useState('');
    let logo = "";
    try {
      const infoUser = jwt_decode(this.props.token);
      if (infoUser.logo) {
        logo = infoUser.logo;
      }
    } catch (error) {}
    const { t } = this.props;
    return (
      <>
        <Box
          sx={BoxContainerPc}
          ref={this.miElementoRef}
          style={{ background: "transparent", paddingRight: "0.1rem" }}
        >
          {/* Language buttons */}
          <div
            style={{
              display: this.state.isHidden ? "none" : "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "8%",
            }}
          >
            {/* ESP */}
            <button
              style={{
                background: "#3E7A5F",
                cursor: "pointer",
                border: "none",
                color: "white",
                padding: "5px 7px 5px 7px",
                gap: "7px",
                borderRadius: "9px 0px 0px 0px",
                opacity: "0px",
                outline: "none"
              }}
              onClick={() => {
                this.setState({ language: "es", languageDefault: "es" });
                this.changeLanguage("es")
              }}
              
            >
              <img src={worldIcon} style={{ marginRight: "0.5rem" }} />
              ESP
            </button>

            {/* EN */}
            <button
              style={{
                cursor: "pointer",
                background: "#3E7A5F",
                outline: "none",
                border: "none",
                color: "white",
                padding: "5px 7px 5px 7px",
                gap: "7px",
                borderRadius: "0px 9px 0px 0px",
                opacity: "0px",
              }}
              onClick={() => {
                this.setState({ language: "en", languageDefault: "en" });
                this.changeLanguage("en")
              }}
            >
              <img src={worldIcon} style={{ marginRight: "0.5rem" }} />
              ENG
            </button>
          </div>
          <Grid
            container
            spacing={0}
            sx={gridContainerNav}
            style={{
              borderTopLeftRadius: "72px",
              borderBottomLeftRadius: "72px",
              paddingRight: "5rem",
              border: "3px solid #59B089",
              background: "#59B089",
            }}
          >
            <Grid item style={{ ...gridItem1nav, borderRadius: "72px" }} xs={3}>
              <Link to="/">
                <CardMedia
                  component="img"
                  image="/logo-colcx-green.svg"
                  alt="logo compañia"
                  sx={imgLogoNav}
                />
              </Link>
            </Grid>

            <Grid
              item
              xs={9}
              sx={gridItem2nav}
              // style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <StyledHeaderContainerNavLi>
                <Link
                  to={"/global-consultation"}
                  className="nav-link"
                  onClick={() => this.handleLinkClick("/global-consultation")}
                  style={{
                    borderRadius: "30px",
                    background:
                      selectedLink === "/global-consultation" ? "#59B089" : "",
                    color:
                      selectedLink === "/global-consultation"
                        ? "white"
                        : "white",
                  }}
                >
                  {t("nav.globalConsultation")}
                </Link>
              </StyledHeaderContainerNavLi>

              {links(token).map(
                (link) =>
                  link.show && (
                    <Fragment key={link.to}>
                      {link.childNodes && link.childNodes.length > 0 ? (
                        <StyledHeaderContainerNavLi key={link.to}>
                          <span
                            className={`nav-link dropdown`}
                            style={{
                              borderRadius: "30px",
                              background:
                                selectedLink === link.to ? "#59B089" : "",
                              color:
                                selectedLink === link.to ? "white" : "white",
                            }}
                            onClick={() => this.handleLinkClick(link.to)}
                          >
                            {t(`${link.label}`)}
                          </span>
                          <StyledHeaderContainerNavLiSub>
                            {link.childNodes.map((linkSub) => (
                              <Link
                                key={linkSub.to}
                                className="nav-link"
                                to={linkSub.to}
                                // style={{
                                //   borderRadius: '30px',
                                //   background: selectedLink === link.to ? '#59B089' : '',
                                //   color: selectedLink === link.to ? 'white' : 'rgba(0, 0, 0, 0.7)'
                                // }}
                                // onClick={() => this.handleLinkClick(link.to)}
                              >
                                {t(`${linkSub.label}`)}
                              </Link>
                            ))}
                          </StyledHeaderContainerNavLiSub>
                        </StyledHeaderContainerNavLi>
                      ) : (
                        <StyledHeaderContainerNavLi key={link.to}>
                          {!token && (
                            <Link
                              key={link.to}
                              // className="nav-link"
                              className={`nav-link `}
                              to={link.to}
                              style={{
                                borderRadius: "30px",
                                background:
                                  selectedLink === link.to ? "#59B089" : "",
                                color:
                                  selectedLink === link.to ? "white" : "white",
                              }}
                              onClick={() => this.handleLinkClick(link.to)}
                            >
                              {t(`${link.label}`)}
                            </Link>
                          )}
                          {token && link.to !== "/projects" && (
                            <Link
                              key={link.to}
                              className="nav-link"
                              to={link.to}
                            >
                              {t(`${link.label}`)}
                            </Link>
                          )}
                        </StyledHeaderContainerNavLi>
                      )}
                    </Fragment>
                  )
              )}
            </Grid>

            {/* <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {/* Register icon */}
            {/* <Link to="/register">
                <Tooltip title={t("register")} arrow>
                  <LoupeIcon
                    style={{
                      marginRight: 10,
                      color: "#59B089",
                      fontSize: "35px",
                    }}
                  />
                </Tooltip>
              </Link>
               */}
            {/* Login icon */}
            {/* <Link to="/login">
                <Tooltip title={t("login")} arrow>
                  <PersonAddIcon
                    style={{
                      marginRight: 20,
                      color: "#59B089",
                      fontSize: "35px",
                    }}
                  />
                </Tooltip>
              </Link> */}

            {/* Language select */}
            {/* <ReactFlagsSelect
                defaultCountry={
                  languageDefault === "en"
                    ? "US"
                    : languageDefault === "es"
                    ? "ES"
                    : "ES"
                }
                placeholder="..."
                style={{ color: "#fff", paddingBottom: 0 }}
                countries={["US", "ES"]}
                onSelect={(e) => {
                  this.setState({ language: e, languageDefault: e });
                  this.changeLanguage(
                    e === "US" || e === "en"
                      ? "en"
                      : e === "es" || e === "EN"
                      ? "es"
                      : "es"
                  );
                }}
                customLabels={{ US: "en", ES: "es" }}
              /> */}

            {/* </Grid> */}

            {/* <Grid item xs={2} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <CottageIcon style={{ marginRight: 5, color: '#59B089' }} />
                <Typography variant="body">Calle 12, Medellin</Typography>
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LocalPhoneIcon style={{ marginRight: 5, color: '#59B089' }} />
                <Typography variant="body">+57 313 8323108</Typography>
              </Grid> */}

            {/* <Grid item xs={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FacebookIcon style={{ marginRight: 5, color: '#59B089' }} />
                <XIcon style={{ marginRight: 5, color: '#59B089' }} />
                <WhatsAppIcon style={{ marginRight: 5, color: '#59B089' }} />
                <InstagramIcon style={{ marginRight: 5, color: '#59B089' }} />
              </Grid> */}

            {/* <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PersonAddIcon style={{ marginRight: 3, color: '#59B089' }} />
                <Link to="/register">
                  <div style={{ color: "black", marginRight: 10 }}>{t("register")}</div>
                </Link>

                <LoginIcon style={{ marginRight: 3, color: '#59B089' }} />
                <Link to="/login">
                  <div style={{ color: "black" }}>{t("login")}</div>
                </Link>
              </Grid> */}
          </Grid>

          {/* <StyledHeader> */}

          {/* <StyledHeaderUp>
            <StyledHeaderContainerMenuBar>
              <FontAwesomeIcon className="menuBars" icon="bars" size={"lg"} style={{ margin: "5px", cursor: "pointer", }} onClick={this.viewNav.bind(this)} />
            </StyledHeaderContainerMenuBar>



            {token && (
              <div className="company" style={{ width: "70%" }}>

                <Row>
                  <Col xs={8} className={"text-center"}>
                    <strong>{t("account")}:</strong> {infoUsers.company.name}
                  </Col>

                  <Col xs={4}>
                    <Select
                      styles={true}
                      options={this.transformSelect(infoUsers.userCompanies)}
                      onChange={this.onChange("company")}
                      value={company}
                    />

                  </Col>
                </Row>
              </div>
            )}

            <div className="auth" id="headerId" style={{ width: token ? "13%" : "", textAlign: "center", background: "#153E354", color: "black" }}>

              {!token && (
                <StyledHeaderContainerNavLiMyAccount
                  key={0}
                  style={{
                    verticalAlign: "middle",
                    background: "#153E35",
                    width: "100%",
                    height: 44,
                  }}
                  id="navbarId"
                >
                  <Link to="/register">
                    <div style={{ color: "white" }}>{t("register")}</div>
                  </Link>
                  <Link to="/login">
                    <div style={{ color: "white" }}>{t("login")}</div>
                  </Link>
                </StyledHeaderContainerNavLiMyAccount>
              )}
              {token && this.renderNavMyAccount(token)}
            </div>

            <StyledHeaderContainerLanguage>
              <ReactFlagsSelect
                defaultCountry={languageDefault}
                placeholder="..."
                style={{ color: "#fff" }}
                //countries={["US", "es"]}
                onSelect={(e) => {
                  let language = this.convertLanguage(e);
                  this.changeLanguage(language);
                  this.setState({ language, languageDefault: e });
                }}
                customLabels={{ US: "English", ES: "Español" }}
              />
            </StyledHeaderContainerLanguage>

          </StyledHeaderUp> */}

          {/* <StyledHeaderContainer>
            <StyledHeaderContainerLogo>
              <Link to="/">
                <img src="/logo-colcx-white.svg" style={{ width: "150px" }} alt="Registro de carbono" />
              </Link>
            </StyledHeaderContainerLogo>
            <StyledHeaderContainerNav
              className={classStyledHeaderContainerNav}
            // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <li className="close">
                <FontAwesomeIcon
                  className="menuBars"
                  icon="window-close"
                  size={"2x"}
                  onClick={this.viewNav.bind(this)}
                />
              </li>

              <StyledHeaderContainerNavLi>
                <Link to={'/global-consultation'} className="nav-link">
                  {t("nav.globalConsultation")}
                </Link>
              </StyledHeaderContainerNavLi>

              {links(token).map((link) =>
                link.show && (
                  <Fragment key={link.to}>
                    {link.childNodes && link.childNodes.length > 0 ? (
                      <StyledHeaderContainerNavLi key={link.to}>
                        <span className="nav-link dropdown">
                          {t(`${link.label}`)}
                        </span>
                        <StyledHeaderContainerNavLiSub>
                          {link.childNodes.map((linkSub) => (
                            <Link
                              key={linkSub.to}
                              className="nav-link"
                              to={linkSub.to}
                            >
                              {t(`${linkSub.label}`)}
                            </Link>
                          ))}
                        </StyledHeaderContainerNavLiSub>
                      </StyledHeaderContainerNavLi>
                    ) : (
                      <StyledHeaderContainerNavLi key={link.to}>
                        {!token && (
                          <Link key={link.to} className="nav-link" to={link.to}>
                            {t(`${link.label}`)}
                          </Link>
                        )}
                        {token && link.to !== "/projects" && (
                          <Link key={link.to} className="nav-link" to={link.to}>
                            {t(`${link.label}`)}
                          </Link>
                        )}
                      </StyledHeaderContainerNavLi>
                    )}
                  </Fragment>
                )
              )}

              {token && (
                <StyledHeaderContainerNavLi>
                  {myTrading(token).length > 0 && (
                    <Link className="nav-link dropdown" to="/info-trading">
                      Trading
                    </Link>
                  )}
                  {myTrading(token).length > 0 && (
                    <StyledHeaderContainerNavLiSub>
                      {myTrading(token).map((nav) => (
                        <Link key={nav.to} className="nav-link" to={nav.to}>
                          {t(`${nav.label}`)}
                        </Link>
                      ))}
                    </StyledHeaderContainerNavLiSub>
                  )}
                </StyledHeaderContainerNavLi>
              )}

              {token && (
                <Fragment>
                  {this.renderNav(token)}
                  <StyledHeaderContainerNavLi>
                    {token && (
                      <Link className="nav-link" to="/contact" id="navbarId">
                        {t("nav.contact")}
                      </Link>
                    )}
                  </StyledHeaderContainerNavLi>
                </Fragment>
              )}

            </StyledHeaderContainerNav>
            <StyledHeaderContainerLogo>
              {token && logo.length > 0 && (
                <img src={logo} style={{ width: "250px" }} alt="" />
              )}
            </StyledHeaderContainerLogo>
          </StyledHeaderContainer> */}

          {/* </StyledHeader> */}
        </Box>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <HeaderPhone {...this.props} />
        </Box>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
const mapStateToProps = ({ user }) => ({
  token: user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
