import * as React from "react";
import { Fragment } from "react";
// import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { commentsGlobal } from "../../actions/project";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { Container as MyContainer, Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Troubleshoot } from "@mui/icons-material";
import Helper from "../Helper/Helper";
import { platform } from "../../Style/index";
import actionGlobalConsultation from "../../actions/gobalConsultation";
import "./Css/Dialog.css";
import { Button, Form } from "react-bootstrap";
import { Alert, Col } from "react-bootstrap";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": { 
    minWidth: "80rem",
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 , fontFamily: 'Montserrat', fontWeight: 700, color: "#59B089", paddingLeft: "1rem"}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogModalGlobal(props) {
  const {
    view,
    tittle,
    message,
    buttons,
    comment,
    t,
    setComment,
    commentDialog,
    alert,
    alertMessage,

    downloadDocument = false,
    columns,
    alertType,
    projectId,
    loadPage,
    consult,
    consultState = false,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [close, setClose] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [messageDialog, setMessageDialog] = React.useState(false);
  React.useEffect(() => {
    if (view) {
      if (consultState) {
        loadPage(true);
        consult(projectId).then((response) => {
          if (response.status) setComments(response.comments);
        });
        loadPage(false);
      } else {
        setComments(setComment);
      }
    }
  }, [view]);
  const downloadCommentDocument = async (projectId, id) => {
 
    loadPage(true);
    const data = { project_id: projectId, comment_id: id};
    const result = await actionGlobalConsultation.getDownloadCommentDocument(
      data
    );

    if (result.status === 200) {
      window.open(result.response.localFileNameUrl, "_blank");
      loadPage(false);
    }else {
      loadPage(false);
    }
  };

  return (
    <div>
      {
        <BootstrapDialog
          // onClose={close}
          size="lg"
          style={{ minWidth: "80rem" }}
          aria-labelledby="customized-dialog-title"
          open={view}
          TransitionComponent={Transition}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.onClose}
          >
            {tittle}
          </BootstrapDialogTitle>
          <DialogContent dividers style={{ minWidth: "500px" }}>
            <Typography gutterBottom>{message}</Typography>
            {props.render}
            {loading && <LinearProgress color="secondary" />}
            {alert && (
              <Fragment>
                <Col xs={12}>
                  <Alert variant={alertType}>
                    <ol>
                      {alertMessage &&
                        typeof alertMessage === "object" &&
                        alertMessage.map((e, idx) => (
                          <li key={idx}>{e.message}</li>
                        ))}
                      {alertMessage && typeof alertMessage === "string" && (
                        <li>{alertMessage}</li>
                      )}
                    </ol>
                  </Alert>
                </Col>
              </Fragment>
            )}
            {comments && (
              <Grid item xs={12}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{
                    borderRadius: "20px",
                    fontFamily: "Montserrat",
                  }}
                >
                  <TableHead className="colcx-table">
                    <TableRow className="colcx-table">
                      {columns &&
                        columns.length > 0 &&
                        columns.map((column) => (
                          <TableCell className="colcx-table-cell-header">
                            {t(column.label)}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {comments &&
                      comments.length > 0 &&
                      comments.map((row) => (
                        <TableRow className="colcx-table">
                          {columns &&
                            columns.length > 0 &&
                            columns.map((column) => (
                              <TableCell
                                align={"center"}
                                style={{ fontFamily: "Montserrat" }}
                              >
                                {column.icon ? <IconButton aria-label="download">
                                <FileOpenIcon
                                  onClick={() =>
                                    downloadCommentDocument(projectId,row.id)
                                  }
                                />
                              </IconButton> : row[column.value]}
                              </TableCell>
                            ))}

                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {buttons &&
                buttons.map((row) => (
                  <Button
                    onClick={row.onclick}
                    variant="contained"
                    color="secondary"
                    style={{
                      background: row.color,
                      borderRadius: 2,
                      textTransform: "none",
                      fontSize: 16,
                      marginTop: 7,
                      marginLeft: 10,
                      marginBottom: 7,
                      display: "flex",

                      ...platform.buttonSuccess,
                      //marginRight: "5px"
                    }}
                  >
                    {row.value}
                  </Button>
                ))}
            </div>
          </DialogActions>
        </BootstrapDialog>
      }
    </div>
  );
}
