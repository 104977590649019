import styled from "styled-components";

export const StyledLayout = styled.div`
  
`;

export const StyledLayouTwo = styled.div`
  margin-left: 65px;
  padding-top: 70px;
  padding-left: 50px;
  padding-right: 50px;
`;
