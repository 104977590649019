import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HeaderTwo from "./Header/HeaderTwo";
import { StyledLayout, StyledLayouTwo } from "./StyledLayout";

const Layout = props => {

  return (
    <Fragment>
      {props.token !== null ?
        <>
          <HeaderTwo {...props} />
        </>

        :
        <>
          <Header {...props} />
          <StyledLayout {...useTranslation}>{props.children}</StyledLayout>
          <Footer />
        </>
      }


    </Fragment>
  );
};

export default Layout;
