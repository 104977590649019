import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faUser, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import React, { Component, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getToken, validToken } from "./actions/user";
import "./App.css";
import Layout from "./components/Layout";
import LoadPage from "./components/Load/LoadPage";
import Routers from "./Routers";

import { NotificationProvider } from "./contexts/NotificationsContext";
import { SnackbarProvider, useSnackbar } from 'notistack';


library.add(faBars, faWindowClose, faUser);

const actions = {
  getToken,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPage: true,
    };
    this.validateToken();
  }

  componentWillMount() {
    //detect change url
    this.unlisten = this.props.history.listen((location, action) => {
      window.scroll(0, 0);
      window.dataLayer.push({
        event: "VirtualPageview",
        virtualPageURL: location.pathname,
      });
    });
  }

  componentDidMount() {
   
    
    window.dataLayer = window.dataLayer || [];
    setTimeout(() => {
      this.setState({ loadPage: false });
    }, 2);
  }

  validateToken() {
    const token = localStorage.getItem("KarbonoaTOKEN");
    if (token) {
      validToken({ token }).then((response) => {
        if (response.status === 1 && response.valid === true) {
          return this.props.getToken(token);
        }
        localStorage.removeItem("KarbonoaTOKEN");
        this.props.getToken(null);
        return this.props.history.push("/login");
      });
    }
  }

  render() {
    const { token } = this.props;
    return (
      <Suspense fallback={<Loader />}>
        <div className="App">
          {!this.state.loadPage && (
            <Layout {...this.props}>
              <Routers token={token} />
            </Layout>
          )}
        </div>
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);
const mapStateToProps = ({ user }) => ({
  token: user.token,
});

const Loader = () => <LoadPage load />;
const AppTRa = withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
function AppT() {
  return (
    <Suspense fallback={<Loader />}>
      <NotificationProvider>
        <SnackbarProvider maxSnack={7}>
          <AppTRa />
        </SnackbarProvider>
      </NotificationProvider>
    </Suspense>
  );
}

export default AppT;
