import { GET } from "./";
export const projectEmitCertification = {
  getEmitCertificationReport: () =>
    GET(`/api/projectEmitCertification/getEmitCertificationReport`),
  getReasonUsingCertificate: (certificatedId) =>
    GET(
      `/api/projectEmitCertification/getReasonUsingCertificate/${certificatedId}`
    ),

  getDocumentVerifier: (verifierId) => GET(`api/verifier/file/${verifierId}`),
};
