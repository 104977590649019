import { GET } from "./";
export const projectEmitCertificationModificationRequest = {
  getBeforeCertificate: (certificatedId) =>
    GET(`/api/projectEmitCertificationModificationRequest/${certificatedId}`),
  getBeforeCertificateKg: (certificatedId) =>
    GET(
      `/api/projectEmitCertificationModificationRequest/kg/${certificatedId}`
    ),
  getBeforePDF: (certificatedId) =>
    GET(
      `/api/projectEmitCertificationModificationRequest/${certificatedId}/pdf`
    ),
};
