import * as React from "react";
import { Fragment } from "react";
// import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { Container as MyContainer, Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Troubleshoot } from "@mui/icons-material";
import Helper from "./../Helper/Helper";
import { platform } from "../../Style/index";
import { Button, Form } from "react-bootstrap";
import { Alert, Col } from "react-bootstrap";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogComponent(props) {
  const {
    view,
    tittle,
    message,
    buttons,
    comment,
    t,
    inputs,
    setComment,
    commentDialog,
    alert,
    alertMessage,
    alertType,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [close, setClose] = React.useState(false);
  const [messageDialog, setMessageDialog] = React.useState(false);

  return (
    <div>
      {
        <BootstrapDialog
          // onClose={close}

          aria-labelledby="customized-dialog-title"
          open={view}
          TransitionComponent={Transition}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.onClose}
          >
            {tittle}
          </BootstrapDialogTitle>
          <DialogContent dividers style={{ minWidth: "500px" }}>
            <Typography gutterBottom>{message}</Typography>
            {props.render}
            {loading && <LinearProgress color="secondary" />}
            {alert && (
              <Fragment>
                <Col xs={12}>
                  <Alert variant={alertType}>
                    <ol style={{ margin: 0 }}>
                      {alertMessage && typeof alertMessage === "string" && (
                        <p>{alertMessage}</p>
                      )}
                      {alertMessage &&
                        typeof alertMessage === "object" &&
                        alertMessage.map((e, idx) => (
                          <li key={idx}>{e.message}</li>
                        ))}
                    </ol>
                  </Alert>
                </Col>
              </Fragment>
            )}
            {comment && (
              <Grid item xs={12}>
                <Form.Group>
                  <Form.Label>
                    {t("dialog.commentTransactions")}{" "}
                    {/* <Helper text={t("helper.projectDescription")} /> */}
                  </Form.Label>
                  <Form.Control
                    onChange={(event) => {
                      setComment(event.target.value);
                    }}
                    value={commentDialog || ""}
                    as="textarea"
                    rows="2"
                  />
                </Form.Group>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {buttons &&
                buttons.map((row) => (
                  <Button
                    onClick={row.onclick}
                    variant="contained"
                    color="secondary"
                    style={{
                      background: row.color,
                      borderRadius: 2,
                      textTransform: "none",
                      fontSize: 16,
                      marginTop: 7,
                      marginLeft: 10,
                      marginBottom: 7,
                      display: "flex",

                      ...platform.buttonSuccess,
                      //marginRight: "5px"
                    }}
                  >
                    {row.value}
                  </Button>
                ))}
            </div>
          </DialogActions>
        </BootstrapDialog>
      }
    </div>
  );
}
