const createNotification = (data) => {
    const news = Object.keys(data).map((info) => {
        let fieldName = info;

        switch (info) {
            case 'name':
                fieldName = 'Nombre';
                break;
            case 'document_number':
                fieldName = 'Identificación';
                break;
            case 'email':
                fieldName = 'Correo';
                break;
            case 'company':
                fieldName = 'Empresa';
                break;
            case 'message':
                fieldName = 'Mensaje';
                break;

            default:
                break;
        }

        return {
            name: info,
            message: `El campo ${fieldName} está vacío`
        };
    });
    return news;
};

const validateEmptyValues = (formValues) => {
    const emptyFields = {};

    for (const key in formValues) {
        if (!formValues[key] || formValues[key].trim() === '') {
            emptyFields[key] = true;
        } else {
            emptyFields[key] = false;
        }
    }

    const nonEmptyFields = Object.fromEntries(
        Object.entries(emptyFields).filter(([key, value]) => value === true)
    );

    return nonEmptyFields;
};

const validateEmail = (value) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(value)) {
        return {
            isValid: false,
            message: 'El formato del correo electrónico no es válido.'
        };
    } else {
        return { isValid: true };
    }
};

const resetErrors = (stateErrors) => {
    stateErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        for (const key in newErrors) {
            newErrors[key] = false;
        }
   
        return newErrors;

    });
}

const validateCommentsForm = (values, stateErrors) => {
    const emptyValuesCheck = validateEmptyValues(values);
    let news = [];

    // validar campos vacios
    if (Object.keys(emptyValuesCheck).length > 0) {
        
        news = [...news, ...createNotification(emptyValuesCheck)];
    }

    // validar formato de email
    const emailValidation = validateEmail(values.email);
    if (values.email && !emailValidation.isValid) {
        const emailNews = {
            name: 'email',
            message: 'El formato del correo electrónico no es válido'
        };
        news.push(emailNews);
    }


    if (news.length > 0) {
        return { state: true, news: news };
    } else {
        resetErrors(stateErrors);
        return false;
    }
};

export default { validateEmptyValues, validateEmail, validateCommentsForm };



