import React, { Component, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import {
  getUser,
  userChangeActive,
  userChangePermit,
  validToken,
} from "./../../actions/user";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import { config as serverUploadConfig } from "../../helpers/serverUpload";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FilePond, registerPlugin } from "react-filepond";
import { getDocumentsRegister } from "../../actions/documentsRegister";
import "react-international-phone/build/index.css";
//import PhoneInput from "react-phone-number-input";
import { PhoneInput } from "react-international-phone";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  getCitiesByRegionId,
  getCountries,
  getRegionsByCountryId,
} from "../../actions/country";
import { getTypeAccountsActives } from "../../actions/typeAccount";
import { setUser } from "../../actions/user";
import DocumentNumber from "../Form/DocumentNumber";
import Error from "../Form/Error";
import Select from "../Form/Select";
import { getTermCondition } from "../../actions/termCondition";
import { platform } from "../../Style/index";
import LoadPage from "../Load/LoadPage";
import HabeasData from "./HabeasData";
import Terms from "./Terms";
import { documentsRegister } from "../../api/documentsRegister";
import DialogComponent from "../Project/Dialog";
import "./Register.css";
import iconPrimary from "./../../images/iconPrimary.png";
import iconSegundary from "./../../images/iconSegundary.png";
import iconTercer from "./../../images/iconTercer.png";

const actions = {
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
};

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPage: true,
      documentsRegister: {},
      typesAccounts: [],
      form: {},
      showTerms: false,
      showHabeasData: false,
      isDisabledSave: false,
      typeAccount: "",
      alternativeText: "",
      typeDocument: null,
      documentNumber: "",
      name: "",
      documentsRegisterType: [],
      email: "",
      emailAgain: "",
      address: "",
      modalMessages: false,
      message: "",
      comment: "",
      phone: "",
      contactName: "",
      phoneCode: "",
      country: null,
      region: null,
      city: null,
      user: {},
      commentDialog: "",
      recaptchaValue: "",
      isAuthorized: false,
      termCondition: "",
      habeasData: "",
      isVerificationDigit: false,
      verificationDigit: "",
      formatDocumentNumber: "",
      formatCellPhone: "",
      token: false,
    };
  }

  componentDidMount() {
    getUser().then((response) => {
      if (response.status) {
        this.setState({ user: response.user, loadPage: false });
      }
      this.setState({ loadPage: false });
    });
  }

  render() {
    const { countries, regions, cities } = this.props;
    const { loadPage, email, user, phone, contactName, token } = this.state;
    const { t } = this.props;
    return (
      <Container style={{ marginTop: token ? 0 : 20 }}>
        <LoadPage load={loadPage} />

        <Row>
          <Grid container spacing={1}>
            <Col
              xs={12}
              sm={12}
              md={12}
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h2 className="card-header-title" style={{paddingLeft: 0, paddingBottom: "1rem"}}>{t("nav.user")}</h2>
            </Col>{" "}
          </Grid>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <Grid container spacing={1} style={{ width: "50%" }}>
            <Grid item xs={12} sm={12} md={12}>
              <Card
                className="custom-card custom-card-height"
                style={{ borderRadius: "30px" }}
              >
                <Card.Header className="card-custom-header">
                  <ListItemIcon style={{ alignItems: "center" }}>
                    {" "}
                    <img
                      src={iconTercer}
                      style={{ width: "2.5rem", height: "2.5rem" }}
                    ></img>
                    <legend className="card-header-title">
                      {t("formRegister.contactUser")}
                    </legend>
                  </ListItemIcon>
                </Card.Header>
                <Card.Body>
                  <fieldset>
                    <Grid container style={{ marginTop: "20px" }} spacing={1}>
                      <Grid item xs={12}>
                        <Form.Group as={Col} controlId="formGridContactName">
                          <Form.Label>
                            {t("formRegister.contactName")}
                          </Form.Label>
                          <Form.Control
                            disabled={true}
                            type="text"
                            placeholder={t("formRegister.contactName")}
                            value={user && user.name}
                            autoComplete="autoCompleteOff"
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12}>
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>{t("formRegister.email")}</Form.Label>
                          <Form.Control
                            disabled={true}
                            type="email"
                            placeholder={t("formRegister.email")}
                            value={user && user.email}
                            autoComplete="autoCompleteOff"
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12}>
                        <Form.Group as={Col} controlId="formGridCellPhone">
                          <Form.Label>{t("formRegister.cellPhone")}</Form.Label>
                          <Form.Control
                            disabled={true}
                            type="text"
                            placeholder={t("formRegister.cellPhone")}
                            value={user && user.phoneNumber}
                            autoComplete="autoCompleteOff"
                          />
                        </Form.Group>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Button
                          style={{ background: "#59B089" }}
                          variant="info"
                          onClick={() =>
                            this.props.history.push("/change-password")
                          }
                        >
                          {t("changePassword")}
                        </Button>
                      </Grid>
                    </Grid>
                  </fieldset>
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const mapStateToProps = ({ general, user }) => ({
  countries: general.countries,
  regions: general.regions,
  cities: general.cities,
  token: user.token,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyProfile)
);
