import React, { Component, useState } from "react";

import {
  Card,
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import actionTypeAccountPermit from "./../../actions/typeAccountPermit";
import DocumentNumber from "../Form/DocumentNumber";

// Material Table
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  getUsers,
  userChangeActive,
  userChangePermit,
  validToken,
} from "./../../actions/user";
import { getTypeAccountsActives } from "../../actions/typeAccount";
import Select from "../Form/Select";

import Helper from "./../Helper/Helper";
import LoadPage from "./../Load/LoadPage";
import { getToken, userAuthentication, updateCompanyInfo } from "../../actions/user";
import {
  getCitiesByRegionId,
  getCountries,
  getRegionsByCountryId,
} from "../../actions/country";
import jwt_decode from "jwt-decode";

import { Countries } from "../../api/country";
const actions = {
  getCountries,
  getRegionsByCountryId,
  getCitiesByRegionId,
  getToken,
};

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: false,
      admin: false,
      pageLoad: true,
      users: [],
      permits: [],
      usersPermit: {},
      isError: false,
      error: "",
      alternativeText: "",
      showAlert: false,
      company: {},
      address: null,
      country: null,
      region: null,
      city: null,
      name: "",
      company: null,
      country: null,
      region: null,
      city: null,
      typeAccount: null,
      typesAccounts: [],
      isVerificationDigit: false,
      verificationDigit: "",
      formatDocumentNumber: "",
      formatCellPhone: "",
      countriesT: [],
      regions: [],
      cities: [],
      infoUsers: jwt_decode(props.token)
    };
  }

  componentDidMount() {
    this.setState({ pageLoad: true });
    this.props.getCountries();
    getUsers().then((response) => {
      if (response.status) {
        this.setPermitUsers(this.state.permits, response.users);
        this.setState({
          users: response.users,
          admin: response.admin,
          company: response.company && response.company,
        });
        this.setState({regions: this.props.getRegionsByCountryId(response.company.country_id)})
        this.setState({cities: this.props.getCitiesByRegionId(response.company.country_id, response.company.region_id)})
      }
      this.setState({ pageLoad: false });
      this.props.getCountries();
    });

    actionTypeAccountPermit.get().then((response) => {
      if (response.status) {
        this.setState({
          permits: response.typeAccountPermits
        });
      }
    });

    getTypeAccountsActives().then((response) => {
      if (response.status === 1) {
        const typesAccounts = response.typesAccounts.map((element) => ({
          value: element.id,
          label: element.description,
          data: element,
        }));
        // const defaultAccount = response.typesAccounts.filter((element) => element.id === this.state.typeAccount);
        this.setState({
          typeAccount:
            typesAccounts[0].value.toString() || this.state.typeAccount,
          typesAccounts,
          alternativeText: typesAccounts[0].data.alternativeText || ""
        });
      }
    });

    
  }

  componentWillReceiveProps({ countries, regions, cities }, nextState) {
    const { country, region, city } = this.state;
    const defaultCountry = countries[0];
    if (!country && countries.length) {
      this.props.getRegionsByCountryId(defaultCountry.id);
      this.setState({
        country: defaultCountry.id,
        formatDocumentNumber: defaultCountry.formatDocumentNumber,
        formatCellPhone: defaultCountry.formatCellPhone,
      });
    }
    const defaultRegion = regions[0];
    if (country && !region) {
      this.props.getCitiesByRegionId(this.state.country, defaultRegion.id);
      this.setState({ region: defaultRegion.id, city: null });
    }
    const defaultCity = cities[0];
    if (country && region && !city) {
      this.setState({ city: defaultCity.id });
    }
  }

  transformSelect = (data) => {
    return data
      ? data.map((select) => ({
          value: select.company_id,
          label: select.company_company_id || select.description,
          data: select,
        }))
      : [];
  };
  setPermitUsers = (permits, users) => {
    let usersPermit = {};
    for (const idxPermits in permits) {
      const permit = permits[idxPermits];

      for (const idxUSers in users) {
        const user = users[idxUSers];
        let check = false;
        for (const idx in user.permits) {
          const userPermit = user.permits[idx];
          if (permit.id === userPermit.typeAccountPermitId) {
            check = true;
          }
        }
        usersPermit[`${user.id}_${permit.id}`] = check;
      }
    }
    this.setState({ usersPermit: usersPermit });
  };

  activeChange = (user) => (e) => {
    this.setState({ pageLoad: true, error: "", isError: false });
    const active = e.target.checked;
    userChangeActive(user.id, { active }).then((response) => {
      if (response.status) {
        this.setState({
          users: response.users,
          pageLoad: false,
        });
      } else {
        this.setState({
          pageLoad: false,
          isError: true,
          error: response.error || response.codeMessages[0].message,
        });
      }
    });
  };

  permitChange = (user, typeAccountPermitId) => (e) => {
    this.setState({ pageLoad: true, error: "", isError: false });
    const checked = e.target.checked;
    userChangePermit(user.id, { typeAccountPermitId, checked }).then(
      (response) => {
        if (response.status) {
          this.setState({
            users: response.users,
            pageLoad: false,
            showAlert: true,
            usersPermit: {
              ...this.state.usersPermit,
              [`${user.id}_${typeAccountPermitId}`]: checked,
            },
          });
        } else {
          this.setState({
            pageLoad: false,
            isError: true,
            error: response.error || response.codeMessages[0].message,
          });
        }
      }
    );
  };
  transformLocation = (data) => {
    return data
      ? data.map((location) => ({
          value: location.id,
          label: location.name,
          data: location.data,
        }))
      : [];
  };

  formCheck = (permit, user, disabled) => {
    return (
      <TableCell className="colcx-table-cell-info" key={`${permit.id}-${user.id}`}>
        <Form.Check
          disabled={disabled}
          type="checkbox"
          checked={this.state.usersPermit[`${user.id}_${permit.id}`]}
          value={this.state.usersPermit[`${user.id}_${permit.id}`]}
          onChange={this.permitChange(user, permit.id)}
        />
      </TableCell>
    );
  };

  onDismiss = () => {
    this.setState({ showAlert: !this.state.showAlert });
  };

  // general on change function
  onChange =
  (name, check = false) =>
  (event) => {

    const {company} = this.state
    return this.setState({
      company: {
        ...company,
        [name]: event.target.value
      } 
    });
  };

  
  transformLocation = (data) => {
    return data
      ? data.map((location) => ({
          value: location.id,
          label: location.name,
          data: location.data,
        }))
      : [];
  };


  onChangeCountry = (event) => {
    const optionElement = event.target.childNodes[event.target.selectedIndex];
    const option = JSON.parse(optionElement.getAttribute("data-json"));
    this.setState({
      company: {
        ...this.state.company,
        country_id: event.target.value
      },
      country: event.target.value,
      formatDocumentNumber: option.formatDocumentNumber,
      formatCellPhone: option.formatCellPhone,
      region: null,
      city: null,
    });
    this.props.getRegionsByCountryId(event.target.value);
  };

  onChangeRegion = (event) => {
    this.setState({ region: event.target.value,
      company: {
        ...this.state.company,
        region_id: event.target.value
      },
     });
    this.setState({ city: null });
    this.props.getCitiesByRegionId(this.state.country, event.target.value);
  };

  onChangeCity = (event) => {
    this.setState({ city: event.target.value,
      company: {
        ...this.state.company,
        city_id: event.target.value
      },
     });
  };

  updateCompanyInfo = () => {
    const {region, city, country, company} = this.state
    const data = {region,city,country, company}

    
    this.setState({pageLoad: true})
    updateCompanyInfo(data).then(response => {
      
      if (response.status){
        this.setState({pageLoad: false})
        this.props.history.push("/my-company")
      }else {
        this.setState({pageLoad: false})
      }
    })
    
  }

  render() {
    const { countries, regions, cities } = this.props;
    const {
      address,
      country,
      region,
      admin,
      city,
      name,
      typeAccount,
      typesAccounts,

      isVerificationDigit,
      verificationDigit,
      alternativeText,
      formatDocumentNumber,
      company,
      pageLoad,
      formatCellPhone,
      token,
    } = this.state;
    const { t } = this.props;
    return (
      <Container style={{ marginTop: token ? 0 : 20, minHeight: "600px" }}>
        <LoadPage load={pageLoad} />

        {/* <Row>
          <Col xs={12}>
            <h2>{t("myCompany")}</h2>
          </Col>
          </Row> */}

        <Row>
          <Col xs={12}>
            <h2 className="card-header-title" style={{paddingLeft: 0, paddingBottom: "2rem"}}>{t("myCompany")}</h2>
          </Col>
          <Col md={12}>
            <Form>
              <fieldset>
                <Form.Row>
                  <Form.Group as={Col} md={4} controlId="formGridTypeAccount">
                    <Form.Label>{t("formRegister.accountTypeId")}</Form.Label>

                    <Select
                      styles={false}
                      options={this.transformSelect(
                        this.state.infoUsers.userCompanies
                      )}
                      disabled={true}
                      value={company && company.type_account_id}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={4} controlId="formGridTypeAccount">
                    <Form.Label>{t("formRegister.accountType")}</Form.Label>

                    <Select
                      styles={false}
                      options={typesAccounts}
                      disabled={true}
                      value={company && company.type_account_id}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={4} controlId="formGridName">
                    <Form.Label>{t("formRegister.fullname")}</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={false}
                      onChange={this.onChange("name")}
                      value={company && company.name}
                      autoComplete="autoCompleteOff"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formGridName">
                    <Form.Label>{t("formRegister.ubication")}</Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                        <Form.Group as={Col} controlId="formGridCity">
                          <Select
                            options={this.transformLocation(
                              countries.map((e) => ({
                                ...e,
                                data: {
                                  formatDocumentNumber: e.formatDocumentNumber,
                                  formatCellPhone: e.formatCellPhone,
                                },
                              }))
                            )}
                            disabled={true}
                            label={t("country")}
                            onChange={this.onChangeCountry}
                            value={company && company.country_id}
                          />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridRegion">
                          <Select
                            options={this.transformLocation(regions)}
                            label={t("region")}
                            onChange={this.onChangeRegion}
                            value={company && company.region_id}
                          />
                        </Form.Group>
                          
                        <Form.Group as={Col} controlId="formGridZip">
                          <Select
                            options={this.transformLocation(cities)}
                            label={t("city")}
                            onChange={this.onChangeCity}
                            value={cities.length > 0 ? company && company.city_id : 1}
                          />
                        </Form.Group>
                      </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridRegion">
                    <Form.Label>{t("formRegister.documentType")}</Form.Label>

                    <Form.Control
                      type="text"
                      disabled={true}
                      value={company && company.typeAccount.description}
                      autoComplete="autoCompleteOff"
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridRegion">
                    <Form.Label>{t("formRegister.documentNumber")}</Form.Label>

                    <Form.Control
                      type="text"
                      disabled={true}
                      value={company && company.document_number}
                      autoComplete="autoCompleteOff"
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridRegion">
                    <Form.Label>
                      {t("formRegister.verificationDigit")}
                    </Form.Label>

                    <Form.Control
                      type="text"
                      disabled={true}
                      value={company && company.verification_digit}
                      autoComplete="autoCompleteOff"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} controlId="formGridAddress">
                    <Form.Label>{t("formRegister.address")}</Form.Label>
                    <Form.Control
                      disabled={false}
                      onChange={this.onChange("address")}
                      value={company && company.address}
                      autoComplete="autoCompleteOff"
                    />
                  </Form.Group>
                </Form.Row>
              </fieldset>

              {/* <hr
                style={{
                  color: "#0056b2",
                  borderTop: "1px solid #ccc",
                  width: "100%",
                }}
              /> */}
            </Form>
          </Col>
          <Col
            xs={12}
            className="text-right"
            style={{ marginBottom: 10, marginTop: 5 }}
          >
            {admin && (
              <Button
                style={{ background: "#59B089" }}
                variant="info"
                onClick={() => this.updateCompanyInfo()}
              >
                {t("Guardar")}
              </Button>
            )}
          </Col>
        </Row>

        {this.state.showAlert && (
          <Col style={{ marginBottom: 10, marginTop: 5, textAlign: "center" }}>
            <Alert onClose={this.onDismiss} dismissible>
              <Alert.Heading></Alert.Heading>
              <p>{t("messajeUserPermit")}</p>
            </Alert>
          </Col>
        )}
        <Row>
          <Col xs={12} style={{ marginTop: "2rem" }}>
            <h2 className="card-header-title" style={{paddingLeft: 0}}>{t("users")}</h2>
          </Col>

          <Col xs={12}>
            {this.state.isError && (
              <Alert
                onClose={() => this.setState({ isError: false, error: "" })}
                variant="danger"
                dismissible
              >
                {this.state.error}
              </Alert>
            )}
          </Col>

          <Col
            xs={12}
            className="text-right"
            style={{ marginBottom: 10, marginTop: 5 }}
          >
            {admin && (
              <Button
                style={{ background: "#59B089" }}
                variant="info"
                onClick={() => this.props.history.push("/users/new")}
              >
                {t("buttons.addNewUser")}
              </Button>
            )}
          </Col>

          <Col xs={12}>
            <Paper
              sx={{ width: "100%", overflow: "hidden" }}
              elevation={3}
              style={{ borderRadius: "20px" }}
            >
              <TableContainer
                sx={{ maxHeight: 440, borderRadius: "20px" }}
                style={{
                  borderRadius: "20px",
                  fontFamily: "Montserrat",
                }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{
                    borderRadius: "20px",
                    fontFamily: "Montserrat",
                  }}
                >
                  <TableHead className="colcx-table">
                  <TableRow className="colcx-table">
                      <TableCell className="colcx-table-cell-header" rowSpan={2}>
                        {t("user.name")}
                      </TableCell>
                      <TableCell className="colcx-table-cell-header" rowSpan={2}>
                        {t("formRegister.email")}
                      </TableCell>
                      <TableCell className="colcx-table-cell-header" rowSpan={2}>
                        {t("user.active")}{" "}
                        <Helper text={`helper.userActive`} placement={"left"} />
                      </TableCell>
                      <TableCell
                        className="colcx-table-cell-header"
                        colSpan={this.state.permits.length}
                      >
                        {t("user.permits")}
                      </TableCell>
                    </TableRow>
                    <TableRow className="colcx-table">
                      {this.state.permits.map((permit) => (
                       <TableCell key={permit.id} className="colcx-table-cell-header">
                          {permit.permit}{" "}
                          <Helper
                            text={`helper.permit.${permit.code}`}
                            placement={"left"}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.users.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell className="colcx-table-cell-info">{user.name}</TableCell>
                        <TableCell className="colcx-table-cell-info">{user.email}</TableCell>
                        <TableCell className="colcx-table-cell-info">
                          <Form.Check
                            disabled={!admin || user.permit === 0}
                            type="checkbox"
                            checked={user.active}
                            value={user.active}
                            onChange={() => this.activeChange(user)}
                          />
                        </TableCell>
                        {this.state.permits.map((permit) =>
                          this.formCheck(
                            permit,
                            user,
                            !admin || user.permit === 0
                          )
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);
const mapStateToProps = ({ general, user }) => ({
  countries: general.countries,
  regions: general.regions,
  cities: general.cities,
  token: user.token,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyAccount)
);
