import { User } from "../api/user";

export const SET_TOKEN = "SET_TOKEN";
const setToken = (payload) => ({ type: SET_TOKEN, payload });

export const setUser = (data) => {
  return User.create(data).then((response) => response);
};

export const userAuthentication = (data) => {
  return User.login(data).then((response) => response);
};
export const loginForCompany = (data) => {
  return User.loginForCompany(data).then((response) => response);
};

export const userPasswordRecovery = (data) => {
  return User.passwordRecovery(data).then((response) => response);
};
export const updateCompanyInfo = (data) => {
  return User.updateCompanyInfo(data).then((response) => response);
};

export const validToken = (data) => {
  return User.validToken(data).then((response) => response);
};

export const setPassword = (data) => {
  return User.updatePassword(data).then((response) => response);
};

export const getByDocumentNumber = (documentNumber) => {
  return User.getByDocumentNumber(documentNumber).then((response) => response);
};

export const getSearch = (data) => {
  return User.getSearch(data).then((response) => response);
};

export const getUsers = () => User.get().then((response) => response);
export const getUser = () => User.getUser().then((response) => response);

export const userChangeActive = (userId, data) =>
  User.userChangeActive(userId, data).then((response) => response);

export const userChangePermit = (userId, data) =>
  User.changePermit(userId, data).then((response) => response);

export const userExchange = (data) =>
  User.userExchange(data).then((response) => response);

export const userCreateByCompany = (data) =>
  User.createByCompany(data).then((response) => response);

export const getToken = (token) => {
  return (dispatch) => {
    dispatch(setToken(token));
  };
};
