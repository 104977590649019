import React, { Component, Fragment } from "react";
import ReactLoading from "react-loading";

class LoadPage extends Component {
  render() {
    const { load } = this.props;
    return (
      <Fragment>
        {load && (
          <div>
            <div
              style={{
                position: "fixed",
                background: "rgba(0,0,0,0.5)",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: "1999"
              }}
            />
            <div
              style={{
                position: "fixed",
                zIndex: "2000",
                left: "45%",
                top: "50%"
              }}
            >
              <ReactLoading
                type="spinningBubbles"
                color="#60AAE5"
                width="200px"
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default LoadPage;
