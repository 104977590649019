import * as React from "react";
import Button from "@mui/material/Button";

function CustomButton({ type, style, text, onClick }) {
  if (style === "green") {
    style = {
      backgroundColor: "#59B089",
      color: "white",
      padding: "7px 22px 7px 22px",
      fontFamily: "Montserrat",
      gap: "24px",
      textTransform: "none",
      borderRadius: "37px",
      opacity: "0px",
      marginLeft: "1.5rem",
    };
  }

  return (
    <Button onClick={onClick} type={type} color="primary" style={style}>
      {text}
    </Button>
  );
}

export default CustomButton;
