import { DELETE, GET, POST } from "./";
export const project = {
  save: (method, data) => POST(`/api/project/${method}`, data),
  generateVerificationCode: (data) =>
    POST(`/api/project/generateverificationcode`, data),
  
  commentsGlobal: (data) => GET(`/api/project/commentsGlobal/${data}`),
  activeGlobal: (data) => GET(`/api/project/activeGlobal/${data}`),
  
  getPublicFilter: (data) => POST(`/api/project/public/filter`, data),
  emitCertification: (data) => POST(`/api/project/emitcertification`, data),
  emitCertificationkg: (data) => POST(`/api/project/emitCertificationkg`, data),
  emitCertificationTranfer: (data) =>
    POST(`/api/project/emitcertificationtransfer`, data),

  emitCertificationUpdate: (data) =>
    POST(`/api/project/emitCertificationUpdate`, data),

  calculatePayment: (projectId, paymentMethod) =>
    GET(`/api/project/calcPayment/${projectId}/${paymentMethod}`),
  calculatePaymentEmit: (projectId, quantity) =>
    GET(`/api/project/calcPaymentEmit/${projectId}/${quantity}`),

  getEmitCertification: (emitCertificationId) =>
    GET(`/api/project/emitcertification/${emitCertificationId}`),
  
  getAllEmitCertification: () =>
    GET(`/api/project/emitcertification/public/all`),

  getWithdrawalInformation: () =>
    GET(`/api/project/withdrawalinformation/public/all`),

  getEmitCertificationKg: (emitCertificationId) =>
    GET(`/api/project/emitcertificationKg/${emitCertificationId}`),

  getEmitCertifications: (projectId) =>
    GET(`/api/project/${projectId}/emitcertifications`),

  getByStandardId: (standardId) => GET(`/api/project/standard/${standardId}`),

  getForm: () => GET(`/api/project/form`),
  getInitialInformation: (data) => GET(`/api/projectTwo/${data}`),

  getQuantityCertifications: () => GET(`/api/project/quantitycertifications`),
  getFormEmitCertification: () => GET(`/api/project/formcertification`),
  getSerials: (projectId) => GET(`/api/project/${projectId}/serials`),
  getSerial: (projectId, serial) =>
    GET(`/api/project/${projectId}/serial/${serial}`),

  getMyProjects: () => GET(`/api/project/`),
  getPending: () => GET(`/api/project/pending`),
  getEdit: (id) => GET(`/api/project/edit/${id}`),
  getPublics: () => GET(`/api/project/public`),
  getPublic: (projectId) => GET(`/api/project/public/${projectId}`),
  getDocuments: (projectId, verificationNumber) =>
    GET(`/api/project/documents/${projectId}/${verificationNumber}`),
  getDocumentsZip: (projectId, certificatedId) =>
    GET(`/api/project/documentDownload/${projectId}/${certificatedId}`),
  getDocument: (documentId) => GET(`/api/project/document/${documentId}`),
  getDocumentGov: (data) => POST(`/api/project/documentGov`, data),
  getDocumentLocation: (documentId) =>
    GET(`/api/project/documentLocation/${documentId}`),
  downloadVerificationDocuments: (documentId,verificationNumber) =>
    GET(`/api/project/download-verification-documents/${documentId}/${verificationNumber}`),
  verifySignatureLink: (firm, hashProjectId, hashUserId) =>
    GET(`/api/project/verifylink/${firm}/${hashProjectId}/${hashUserId}`),

  publish: (id) => POST(`/api/project/${id}/publish`),
  stageUpdated: (data) => POST(`/api/project/stageUpdated`, data),
  projectSaveNotes: (data) => POST(`/api/project/saveNotes`, data),

  delete: (id) => DELETE(`/api/project/${id}`),

  activateNextcheck: (id) => GET(`/api/project/nextCheck/${id}`),

  signatureConfirmationValidation: (data) =>
    POST(`/api/project/signature/validation`, data),

  signatureConfirmationMonitoringReport: (data) =>
    POST(`/api/project/signature/monitoringReport`, data),

  signatureConfirmationVerification: (data) =>
    POST(`/api/project/signature/verification`, data),

  signatureConfirmationCertification: (data) =>
    POST(`/api/project/signature/certification`, data),
  signatureConfirmationCertificationCredit: (data) =>
    POST(`/api/project/signature/certification-credit`, data),
  reportProjects: (data) => POST(`/api/project/reportProjects`, data),
};
