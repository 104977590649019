import * as React from 'react';
import { useSnackbar } from 'notistack';

function CustomAlert({ info, variant}) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        info.forEach((news) => {
            enqueueSnackbar(news.message, { variant });
        });
    }, [info, enqueueSnackbar, variant]);

}

export default CustomAlert;
