import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class Helper extends Component {
  render() {
    const { t, text, placement } = this.props;
    return (
      <OverlayTrigger

    
        placement={placement}
        overlay={<Tooltip  id={`tooltip-top`}>{t(text)}</Tooltip>}
      >
        <div
          style={{
            background: "#59B089",
            color: "#fff",
            lineHeight: 1,
            cursor: "pointer",
            width: 20,
            height: 20,
            padding: 3,
            fontSize: 15,
            borderRadius: 5,
            display: "inline-block",
            marginBottom: 5,
            verticalAlign: "middle",
            marginLeft: "0.5rem",
            textAlign: "center",
          }}
        >
          <b>!</b>
        </div>
      </OverlayTrigger>
    );
  }
}

export default withTranslation()(Helper);
