import { projectEmitCertificationModificationRequest } from "../api/projectEmitCertificationModificationRequest";

export const getBeforeCertificate = (certificatedId) => {
  return projectEmitCertificationModificationRequest
    .getBeforeCertificate(certificatedId)
    .then((response) => response);
};

export const getBeforeCertificateKg = (certificatedId) => {
  return projectEmitCertificationModificationRequest
    .getBeforeCertificateKg(certificatedId)
    .then((response) => response);
};

export const getBeforePDF = (certificatedId) => {
  return projectEmitCertificationModificationRequest
    .getBeforePDF(certificatedId)
    .then((response) => response);
};
