import React from "react";
import jwt_decode from "jwt-decode";

import DrawerNotifications from "./DrawerNotifications";

function Notifications() {
    const [token, setToken] = React.useState(null);

    React.useEffect(() => {
        const storedToken = localStorage.getItem('KarbonoaTOKEN');

        if (!storedToken) {
            console.log('No se encontró el token en localStorage');
            return;
        }

        const decodedToken = jwt_decode(storedToken);
        setToken(decodedToken);

    }, []);

    return (
        <>
            <DrawerNotifications token={token} />
        </>
    );
}

export default Notifications;