import React, { createContext, useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";

import actionNotification from '../actions/notification';

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
    const [Notifications, setNotifications] = useState([]);
    const [totalNoti, setTotalNoti] = useState(0);
    const [documentExists, setDocumentExists] = useState(false);

    const requestNotifications = async (data) => {
        try {
            const response = await actionNotification.getNotifications(data);
            if(response.response){
            setNotifications(response.response);
            const unreadCount =await response.response.filter((info) => {
                if (info.viewed === 0) {
                    return true;
                }
                return false;
            })        
            setTotalNoti(unreadCount.length);
            return response;}
        } catch (error) {
            throw error;
        }
    }

    const updateNotificationStatus = async (id, typeAccount, companyId) => {
        try {
            const data = { action: 'update_status', idNotification: id, typeAccount: typeAccount, companyId: companyId };
            const response = await actionNotification.updateNotification(data);
            const unreadCount = await response.response.filter((info) => {
                if (info.viewed === 0) {
                    return true;
                }

                return false;
            });
           
            setNotifications(response.response);
            setTotalNoti(unreadCount.length);
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        const handleTokenChange = () => {
            const token = localStorage.getItem('KarbonoaTOKEN');
         
            if (!token) {
          
                return;
            }

            const fetchData = async () => {
                const infoUser = jwt_decode(token);
                await requestNotifications({ companyId: infoUser.company.companyId, typeAccount: infoUser.typeAccount });
            };
            fetchData();
        };

        handleTokenChange();
        window.addEventListener('storage', handleTokenChange);

    }, [localStorage.getItem('KarbonoaTOKEN')]);

    const notificationValues = {
        requestNotifications, updateNotificationStatus, Notifications, setNotifications, totalNoti,
        documentExists, setDocumentExists,
    };

    return (
        <NotificationContext.Provider value={notificationValues}>
            {children}
        </NotificationContext.Provider>
    );
};

export { NotificationContext, NotificationProvider };
