import styled from "styled-components";
import { styled as styledMui } from "@mui/material";

export const BoxContainerPc = {
  position: 'fixed',
  zIndex: 1000,
  top: 0,
  width: '100%',
  background: 'white',
  padding: { lg: '0px 60px 15px 60px', xl: '0px 60px 25px 60px' },
  display: { xs: 'none', md: 'block' },
  height: { lg: '90px', xl: '120px' },
  // borderBottom: '1px solid grey',
};

export const gridContainerNav = {
  background: 'white'
};

export const gridItem1nav = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  background: "white",
  padding: "0 8%",
};

export const gridItem2nav = {
  display: {md: 'flex', sm: 'hidden'},
  justifyContent: {md: 'flex-end'},
  alignItems: 'center',
};

export const imgLogoNav = {
  width: { lg: '6rem', xl: '6rem' },
  marginTop: "1rem"
};


export const StyledHeader = styled.div`
  background: #fff;
  background-color : #fff;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  height: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  top: 0px;
  border-bottom-color: #34495e;
  z-index: 21;
  .show {
    display: inline-block;
  }
  .hiden {
    display: none;
  }
  @media (min-width: 1055px) {
    .hiden {
      display: inline-block;
    }
  }
`;
export const StyledHeaderUp = styled.div`
  background: #f0f1f9;
  position: relative;
  text-align: right;
  color: black;
  div.auth {
    display: inline-block;
    a {
      display: inline-block;
      margin: 5px;
      color: rgba(0, 0, 0, 0.7);
      opacity: 1;
    }
  }
  div.company {
    display: inline-block;
    width: 75%;
    height: auto;
    text-align: center;
    span {
      display: inline-block;
      text-align: center;
    }
  }
`;

export const StyledHeaderContainerMenuBar = styled.div`
  position: absolute;
  top: 4px;
  @media (min-width: 1055px) {
    .menuBars {
      display: none;
    }
  }
`;

export const StyledHeaderContainerLanguage = styled.div`
  padding: 5px;
  display: inline-block;
  background: #153E354;
  color : "#fff"
  height: 44px;
  width: 13%;
  text-align: center;
}
`;

export const StyledHeaderContainer = styled.div`
  margin-left: 0%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -15px;
`;

export const StyledHeaderContainerLogo = styled.div`
  display: inline-block;
  vertical-align: text-bottom;
  img {
    width: 300px;
    margin-right: 489px;
    vertical-align: baseline !important;
  }
`;

export const StyledHeaderContainerNav = styled.ul`
  position: fixed;
  background: #292b2c;
  padding: 0px;
  top: -16px;
  bottom: 0px;
  left: 0px;
  width: 300px;
  padding: 0px;
  margin: 0px;
  z-index: 100;
  li.close {
    color: #fff;
    font-size: 12px;
    text-align: right;
    cursor: pointer;
    padding: 5px;
    margin-top: 15px;
    display: inline-block;
  }
  @media (min-width: 1055px) {
    display: inline-block!impottant;
    vertical-align: middle;
    position: relative;
    height: auto;
    background: transparent;
    width: auto;
    display: none;
  }
`;

export const StyledHeaderContainerNavLiMyAccount = styled.div`
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
  .nav-link {
    border-radius: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    border: 1px solid transparent;
    border-top: 3px solid transparent;
    width: 100%;
  }
  a {
    opacity: 0.5;
  }
  a:hover {
    opacity: 1;
  }
  :hover > ul {
    display: block;
  }
  .dropdown {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 2px solid transparent;
  }
  .dropdown:focus,
  .dropdown:hover {
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    border-top: 2px solid #60aae5;
  }
  @media (min-width: 1055px) {
    border: 0px solid transparent;
    width: auto;
    .nav-link {
      color: rgba(0, 0, 0, 0.7);
      padding: 10px 14px;
      opacity: 1;
    }
  }
`;

export const StyledHeaderContainerNavLiSubMyAccount = styled.ul`
  padding: 0px;
  margin: 0px;
  text-align: left;
  a {
    border-bottom: 1px solid !important;
    background: #2f2f2f;
  }
  display: none;
  background: #fff;
  border: 1px solid #e9ecef;
  color: rgba(27, 27, 27, 0.8);
  padding: 12px;
  border-radius: 0;
  left: auto;
  border-bottom: 2px solid #60aae5;
  min-width: 200px;
  position: absolute;
  z-index: 1000;
  a {
    margin: 0;
    padding: 8px 12px !important;
    border-radius: 0;
    background: #fff;
    border-bottom: 1px solid #e6e6e6 !important;
    color: #1b1b1b;
  }
`;

export const StyledHeaderContainerNavLi = styled.li`
  text-align: left;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
  border-bottom: 1px solid #ccc;
  .nav-link {
    text-decoration: none;
    border-radius: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    border: 1px solid transparent;
    border-top: 3px solid transparent;
    color: #fff;
    width: 100%;
  }
  .nav-link.selected {
    color: red; 
  }
  a {
  }
  a:hover {
  }
  :hover > ul {
    display: block;
  }
  .dropdown {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 2px solid transparent;
  }
  .dropdown:focus,
  .dropdown:hover {
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    border-top: 2px solid #60aae5;
  }
  @media (min-width: 1055px) {
    border: 0px solid transparent;
    width: auto;
    .nav-link {
      color: rgba(0, 0, 0, 0.7);
      padding: 10px 14px;
    }
  }
`;

export const StyledHeaderContainerNavLiSub = styled.ul`
  padding: 0px;
  margin: 0px;
  a {
    border-bottom: 1px solid !important;
    background: #2f2f2f;
  }
  @media (min-width: 1055px) {
    display: none;
    background: #fff;
    border: 1px solid #e9ecef;
    color: rgba(27, 27, 27, 0.8);
    padding: 12px;
    border-radius: 0;
    left: auto;
    border-bottom: 2px solid #60aae5;
    min-width: 250px;
    position: absolute;
    z-index: 1000;
    a {
      margin: 0;
      padding: 8px 12px !important;
      border-radius: 0;
      background: #fff;
      border-bottom: 1px solid #e6e6e6 !important;
      color: #1b1b1b;
    }
  }
`;
