import * as React from 'react';
import { TextField } from '@mui/material';

function CustomTextFild({
    label,
    name,
    type = 'text',
    variant = "outlined",
    value = '',
    change,
    fullWidth = true,
    multiline = false,
    rows = 0,
    error = false,
}) {
    return (
        <TextField
            label={label}
            name={name}
            type={type}
            variant={variant}
            value={value}
            onChange={change}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            error={error}
        />
    );
}

export default CustomTextFild;