import React, { Fragment } from "react";
import { Alert, Col } from "react-bootstrap";

const Error = ({ options, value }) => {
  return (
    <Fragment>
      <Col xs={12}>
        <Alert variant={value}>
          <ol>
            {options &&
              typeof options === "object" &&
              options.map((e, idx) => <li key={idx}>{e.message}</li>)}
            {options && typeof options === "string" && <li>{options}</li>}
          </ol>
        </Alert>
      </Col>
    </Fragment>
  );
};

export default Error;
