
export const config = (url, module = "") => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("KarbonoaTOKEN")}`,
    platform: "karbonoa",
    module: module
  };

  let urlDocuments = `${process.env.REACT_APP_API}${url}`

  return {
    url :urlDocuments,
    module,

    process: { headers, module },

    revert: {
      headers: {
        Authorization: headers.Authorization,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    restore: { headers },
    load: { headers },
    fetch: { headers },
  };
};


