import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";

import date from "../../js/date";

export default function TableConsultation({ columns, rows, action, filter }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden" }}
      elevation={3}
      style={{ borderRadius: "20px" }}
    >
      {/* <Grid container>

            </Grid> */}
      <TableContainer
        sx={{ maxHeight: 440, borderRadius: "20px" }}
        style={{ borderRadius: "20px", fontFamily: "Montserrat" }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ borderRadius: "20px", fontFamily: "Montserrat" }}
        >
          <TableHead className="colcx-table">
            <TableRow className="colcx-table">
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  className="colcx-table-cell-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rows.length !== 0 &&
              rows
                .filter((ele) =>
                  filter && filter !== ""
                    ? ele.owner_name.includes(filter) ||
                      ele.project_name.includes(filter) ||
                      ele.project_code.includes(filter)
                    : true
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {Object.keys(row).map(
                      (key) =>
                        key !== "id" &&
                        key !== "project_id" &&
                        key !== "user_id" &&
                        key !== "status" &&
                        key !== "owner_name" && (
                          <TableCell
                            align={"center"}
                            key={key}
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {key === "start" || key === "end"
                              ? date.formatDateShort(row[key])
                              : row[key]}
                          </TableCell>
                        )
                    )}
                    {action && (
                      <TableCell
                        align={"center"}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        <Link
                          to={{
                            pathname: `/global-consultation/${row.id}`,
                            state: row,
                          }}
                        >
                          <Chip
                            label={`${t(
                              "globalConsultation.activeTableLabels.participate"
                            )}`}
                            color="success"
                            style={{ background: "rgb(0, 150, 108)" }}
                          />
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                ))) || (
              <TableRow>
                <TableCell
                  align={"center"}
                  colSpan={columns.length}
                  style={{ fontFamily: "Montserrat" }}
                >
                  No hay proyectos disponibles en este momento.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        style={{ fontFamily: "Montserrat" }}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
